<template>
  <div class="home">
    <LvDialog header="" v-model="displayBasic" :style="{ maxWidth: '700px' }">
      <template #header>
        <div>
          <h3 class="mb-1">{{ displayItem.title }}</h3>
          <h5 class="mb-0 detail-text">
            {{ `£${displayItem.amount} x ${displayItem.value} = £${displayItem.amount * displayItem.value}` }}
          </h5>
        </div>
      </template>
      <div class="">
        <!-- <h4 class="mb-3">Payment Details</h4> -->
        <form class="needs-validation" @submit.prevent="formSubmit">
          <div class="mb-3">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" placeholder="you@example.com" required="" />
            <div class="invalid-feedback">Please enter a valid email address for shipping updates.</div>
          </div>

          <div class="mb-3">
            <label for="type">Donation Type</label>
            <select class="custom-select form-control d-block w-100" id="type" required="">
              <option value="General Donation">General Donation</option>
              <option value="Sadqah">Sadqah</option>
              <option value="Zakat">Zakat</option>
            </select>
            <div class="invalid-feedback">Please select a valid type.</div>
          </div>

          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="cc-name">Name on card</label>
              <input type="text" class="form-control" id="cc-name" placeholder="" required="" />
              <small class="text-muted">Full name as displayed on card</small>
              <div class="invalid-feedback">Name on card is required</div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="cc-number">Credit card number</label>
              <input type="text" class="form-control" id="cc-number" placeholder="" required="" />
              <div class="invalid-feedback">Credit card number is required</div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="cc-expiration">Expiration</label>
              <input type="text" class="form-control" id="cc-expiration" placeholder="" required="" />
              <div class="invalid-feedback">Expiration date required</div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="cc-expiration">CVV</label>
              <input type="text" class="form-control" id="cc-cvv" placeholder="" required="" />
              <div class="invalid-feedback">Security code required</div>
            </div>
          </div>

          <div class="mb-3">
            <label for="address">Address</label>
            <input type="text" class="form-control" id="address" placeholder="address" required="" />
            <div class="invalid-feedback">Please enter your shipping address.</div>
          </div>
          <button class="btn btn-success btn-block" style="border-radius: 50px" type="submit">Continue</button>
        </form>
      </div>
      <!-- <template #footer>
        <LvButton label="No" icon="light-icon-x" @click="displayBasic = false" class="lv-button --text-button" />
        <LvButton label="Yes" icon="light-icon-check" @click="displayBasic = false" autofocus />
      </template> -->
    </LvDialog>

    <section class="donatepopup wf-section" id="donate-options" @click="toggleOptions('close', {})">
      <div class="donationWrapper" @click.stop>
        <h1 style="color: white; font-size: 30px" @click.stop>Select Donation For</h1>
        <h3 class="donationheading linkSelection" v-for="data in currentItem.moreItems" :key="data.title">
          <a :href="data.link" target="_blank">
            {{ data.title }}
          </a>
        </h3>
      </div>
      <div data-w-id="44567913-62ce-5393-d531-c4ee0782d899" class="closeicon">X</div>
    </section>

    <Splide
      id="main-slider"
      class="slider"
      :options="{
        drag: false,
        gap: 0,
        perPage: 1,
        perMove: 1,
        type: 'fade',
        arrows: false,
        pagination: false,
        autoplay: true,
        interval: 6000,
        pauseOnHover: false,
        easing: 'linear',
      }"
    >
      <SplideSlide v-for="(item, ind) in $store.state.slides" :key="ind">
        <div
          class="slide"
          :style="{
            'background-image': screenSize ? 'url(' + item.mobileImage + ')' : 'url(' + item.desktopImage + ')',
            'background-position': item.textRight ? '10% 0' : '20% 0',
          }"
        >
          <!-- screenSize ? 'none' : -->
          <div class="slidercontainer w-container">
            <div class="slidediv" :class="{ setRight: item.textRight }">
              <!-- <h1 class="bannerHeading">Complete</h1> -->
              <h1 class="bannerHeading">
                <span class="bg-colored">Ramadan</span>
              </h1>
              <h1 class="bannerHeading">
                <span class="bg-colored">2024</span>
              </h1>
              <!-- @click="toggleDonate('open')" -->
              <a
                data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2"
                href="https://buy.stripe.com/fZe28xa4S2jR2qcfZf"
                target="_blank"
                class="button-primary w-button bannerButton"
                >Donate Now</a
              >
              <!-- <p class="slidepara">“{{ item.quote }}”<br /></p>
              <p class="slideparaend">- {{ item.saidBy }},<br />{{ item.designation }}</p> -->
            </div>
            <!-- <img class="slideImage" :src="item.mobileImage" /> -->
          </div>
        </div>
      </SplideSlide>
    </Splide>
    <div class="my-lightbox" v-if="showLightbox">
      <Splide
        ref="main"
        class="my-lightbox-slider"
        :options="{
          type: 'slide',
          pagination: false,
          arrows: true,
        }"
        @splide:move="handleMove"
      >
        <SplideSlide v-for="(item, ind) in lightboxData" :key="ind" @click="showLightbox = false">
          <!-- :class="{ 'my-lightbox-slide-wrapper': item.type == 'image' }" -->
          <div class="my-lightbox-slide-wrapper">
            <img v-if="item.type == 'image'" :src="item.url" loading="lazy" alt="" @click.stop />
            <div v-else style="width: 940px">
              <div class="my-lightbox-spinner"></div>
              <!-- <figure v-html="item.html"></figure> -->
              <iframe
                :id="`iframe${ind}`"
                class="lightbox-iframe"
                frameborder="0"
                allow="autoplay; fullscreen"
                :src="item.originalUrl"
                style="width: 100%; aspect-ratio: 16/9"
              ></iframe>
              <!-- <video type="video" style="width: 100%; aspect-ratio: 16/9" controls autoplay>
                <source :src="item.originalUrl" type="video/mp4" />
              </video> -->
            </div>
          </div>
        </SplideSlide>
      </Splide>
      <div class="w-lightbox-control w-lightbox-close" @click="showLightbox = false"></div>
      <Splide
        ref="thumbnails"
        class="my-lightbox-thumbnail"
        :options="{
          type: 'slide',
          isNavigation: true,
          gap: 10,
          pagination: false,
          arrows: false,
          autoWidth: true,
        }"
      >
        <SplideSlide v-for="(item, ind) in lightboxData" :key="ind">
          <div class="my-lightbox-thumbnail-item">
            <img :src="item.url" loading="lazy" alt="" />
          </div>
        </SplideSlide>
      </Splide>
    </div>

    <section class="storysection wf-section" id="news">
      <div class="container-2" v-if="currentProject != ''">
        <div class="sub-wrapper">
          <button class="back-button" @click="$router.replace({ name: 'home' })">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="20" height="20" focusable="false">
              <path d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"></path>
            </svg>
          </button>
          <h1 class="centered-heading">
            {{ currentProject }}
          </h1>
        </div>

        <Splide
          class="storybottomslider"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 30,
            perPage: 3,
            perMove: 1,
            type: 'slide',
            arrows: true,
            pagination: false,
            breakpoints: {
              991: {
                perPage: 3,
                gap: 7,
              },
              767: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(item, ind) in currentData" :key="ind">
            <div class="storybottomslide">
              <a
                :href="item.showMore ? '#' : item.link"
                @click="item.showMore && toggleOptions('open', item)"
                target="_blank"
                style="text-decoration: none"
              >
                <div v-if="loading" style="height: 200px; padding: 20px">
                  <lv-skeleton
                    v-for="n in 8"
                    :key="n"
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                </div>
                <template v-else>
                  <!-- <div class="bottomimage image-overlay"></div> -->
                  <img :src="item.image" loading="lazy" alt="" class="bottomimage" />

                  <div class="bottomcontentwrapper">
                    <h1 class="bottomtitle">
                      {{ item.title }}
                    </h1>
                    <p class="bottomparagraph">{{ item.content }}</p>
                    <a data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9" class="button-primary w-button">Donate Now</a>
                  </div>
                </template>
              </a>
            </div>
            <div class="storybottomslide" v-if="false">
              <!-- <a
                :href="item.showMore ? '#' : item.link"
                @click="item.showMore && toggleOptions('open', item)"
                target="_blank"
                style="text-decoration: none"
              >
              </a> -->
              <div v-if="loading" style="height: 200px; padding: 20px">
                <lv-skeleton
                  v-for="n in 8"
                  :key="n"
                  :primaryOpacity="0.2"
                  :width="220"
                  :height="10"
                  primaryColor="#607c8a"
                />
              </div>
              <template v-else>
                <!-- <div class="bottomimage image-overlay"></div> -->
                <img :src="item.image" loading="lazy" alt="" class="bottomimage" />

                <div class="bottomcontentwrapper sub-project">
                  <h1 class="bottomtitle">
                    {{ item.title }}
                  </h1>
                  <p class="bottomparagraph">{{ item.content }}</p>

                  <div class="quantityDiv">
                    <h4 class="detail-text mb-0">£{{ item.amount }}</h4>
                    <h4 class="detail-text mb-0">x</h4>
                    <lv-number
                      placeholder="Enter Number"
                      v-model="item.value"
                      :min="0"
                      :max="999"
                      buttonColor="set-lvButton"
                    />
                  </div>
                  <a
                    data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9"
                    class="button-primary w-button"
                    @click="openDialog(item)"
                    >Donate Now <span class="button-amount">({{ checkValue(item) }})</span></a
                  >
                </div>
              </template>
            </div>
          </SplideSlide>
        </Splide>
      </div>

      <div class="container-2" v-show="currentProject == ''">
        <h1 class="centered-heading left-bordered"><span class="highlightheading">Our</span> Projects</h1>
        <Splide
          class="storybottomslider"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 7,
            perPage: 4,
            perMove: 1,
            type: 'slide',
            arrows: true,
            pagination: false,
            breakpoints: {
              991: {
                perPage: 3,
              },
              767: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(item, ind) in appealData" :key="ind">
            <div class="storybottomslide">
              <router-link :to="filterRoute(item.category)" style="text-decoration: none">
                <div v-if="loading" style="height: 200px; padding: 20px">
                  <lv-skeleton
                    v-for="n in 8"
                    :key="n"
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                </div>
                <template v-else>
                  <div class="bottomimage image-overlay"></div>
                  <img :src="item.image" loading="lazy" alt="project" class="bottomimage" />

                  <div class="bottomcontentwrapper">
                    <h1 href="#" class="bottomtitle">
                      <!-- at-top -->
                      {{ item.title }}
                    </h1>
                    <!-- <p class="bottomparagraph">{{ item.content }}</p> -->
                    <a data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9" href="#" class="button-primary w-button"
                      >Donate Now</a
                    >
                  </div>
                </template>
              </router-link>
              <!-- <a href="#" style="text-decoration: none" @click="setSub(item.title, item.category)">
              </a> -->
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </section>

    <section class="storysection wf-section" v-show="currentProject == ''">
      <div class="container-2">
        <h1 class="centered-heading left-bordered"><span class="highlightheading">Ramadan</span> Video</h1>
        <figure class="video-wrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KAq8o-6k1iI?si=zwOuFjHvtjfXiCzj"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </figure>
      </div>
    </section>

    <section class="donatesection wf-section">
      <div class="donate-container">
        <div class="takeDiv">
          <h1 class="take-heading">Take</h1>
          <h1 class="take-heading green-heading">Action</h1>
          <h4 class="volunteerdetails">
            Children need champions. Get Involved, speak out, volunteer and give everyone a fair chance to succeed.
          </h4>
        </div>
        <div class="takeDiv buttons-wrapper">
          <a href="#" class="button-primary w-button takeButton">Become Volunteer</a>
          <br />
          <!-- @click="toggleDonate('open')" -->
          <a
            data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2"
            href="https://buy.stripe.com/fZe28xa4S2jR2qcfZf"
            target="_blank"
            class="button-primary w-button takeButton"
            >Donate Now</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import axios from "axios";
import LvSkeleton from "lightvue/skeleton";
import LvNumber from "lightvue/number";
import LvDialog from "lightvue/dialog";
import LvButton from "lightvue/button";

import { useToast } from "vue-toastification";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
    Splide,
    SplideSlide,
    LvSkeleton,
    LvNumber,
    LvDialog,
    LvButton,
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      displayBasic: false,
      displayItem: {},
      showLightbox: false,
      lightboxData: [],
      visibleRef: false,
      indexRef: 0,
      imgsRef: ["images/news/92.jpg", "images/news/Jehan e pakistan.jpg", "images/news/Ummat.jpg", "images/a2img1.png"],

      windowSize: window.innerWidth,
      isExpanded: false,
      showRead: false,
      loading: false,
      slideData: [
        {
          quote:
            "Morality Begs Us To Stand Up And Use Our Voice To Ask For Justice With Respect To DR. Aafia Siddiqui.",
          saidBy: "Mike Gravel",
          designation: "Former US Senator",
          desktopImage: require("@/assets/images/worldappeal/flyer/1.jpg"),
          mobileImage: require("@/assets/images/worldappeal/flyer/web-cover2.jpg"),
          textRight: false,
        },
        {
          quote:
            "Morality Begs Us To Stand Up And Use Our Voice To Ask For Justice With Respect To DR. Aafia Siddiqui.",
          saidBy: "Mike Gravel",
          designation: "Former US Senator",
          desktopImage: require("@/assets/images/worldappeal/flyer/1.jpg"),
          mobileImage: require("@/assets/images/worldappeal/flyer/web-cover2.jpg"),
          textRight: false,
        },
        {
          quote:
            "Morality Begs Us To Stand Up And Use Our Voice To Ask For Justice With Respect To DR. Aafia Siddiqui.",
          saidBy: "Mike Gravel",
          designation: "Former US Senator",
          desktopImage: require("@/assets/images/worldappeal/flyer/1.jpg"),
          mobileImage: require("@/assets/images/worldappeal/flyer/web-cover2.jpg"),
          textRight: false,
        },
        // {
        //   quote:
        //     "Morality Begs Us To Stand Up And Use Our Voice To Ask For Justice With Respect To DR. Aafia Siddiqui.",
        //   saidBy: "Mike Gravel",
        //   designation: "Former US Senator",
        //   desktopImage: "images/webslides/2.jpg",
        //   mobileImage: "images/webslides/2.jpg",
        //   textRight: true,
        //   // desktopImage: "images/Artboard1.png",
        //   // mobileImage: "images/mobile_images/mike_gravel.png",
        // },
        // {
        //   quote: "Aafia Is The Most Wronged Woman On The Planet.",
        //   saidBy: "Yvonne Ridley",
        //   designation: "British Journalist",
        //   desktopImage: "images/webslides/1.jpg",
        //   // desktopImage: "images/Artboard8.png",
        //   mobileImage: "images/mobile_images/Ridley image.png",
        //   textRight: false,
        // },
        // {
        //   quote: "Dr Aafia siddiqui was victimized by the international politics being played for power.",
        //   saidBy: "Ramsey Clark",
        //   designation: "Former U.S Attorney General",
        //   desktopImage: "images/webslides/3.jpg",
        //   // desktopImage: "images/Artboard3.png",
        //   mobileImage: "images/mobile_images/ramsey.png",
        //   textRight: false,
        // },
        // // {
        // //   quote:
        // //     "I heard the screams of a woman in Bagram while the FBI and CIA tortured me. That torture centre has been liberated but Aafia remains in prison on bogus, unrealistic charges.",
        // //   saidBy: "Moazzam Begg",
        // //   designation: "Ex-detainee Bagram detention centre",
        // //   desktopImage: "images/Artboard4.png",
        // //   mobileImage: "images/mobile_images/moazzam.png",
        // // },
        // {
        //   quote: "Aafia is an institution in herself.",
        //   saidBy: "Noam Chomsky",
        //   designation: "American Intellectual",
        //   desktopImage: "images/webslides/4.jpg",
        //   // desktopImage: "images/Artboard5.png",
        //   mobileImage: "images/mobile_images/noam.png",
        //   textRight: false,
        // },
        // {
        //   quote:
        //     "The Government of Pakistan is not sincere in bringing back Dr. Aafia. Her life is in grave danger. The criminal negligence of Foreign Ministry in Dr. Aafia's case is evident.",
        //   saidBy: "Talha Mehmood",
        //   designation: "Senator",
        //   desktopImage: "images/webslides/5.jpg",
        //   // desktopImage: "images/Artboard6.png",
        //   mobileImage: "images/mobile_images/talha.png",
        //   textRight: true,
        // },
        // {
        //   quote:
        //     "We have received no cooperation from the Government of Pakistan in securing Dr Siddiqui's repatriation to Pakistan, there's been a lot of talk, but no concrete steps have been taken despite numerous requests for assistance.",
        //   saidBy: "Tina Foster",
        //   designation: "US Attorney",
        //   desktopImage: "images/webslides/6.jpg",
        //   // desktopImage: "images/Artboard7.png",
        //   mobileImage: "images/mobile_images/tina.png",
        //   textRight: false,
        // },
        // {
        //   quote: "If there is an effort made by Pakistan, we could have Aafia back in Karachi within two days.",
        //   saidBy: "Mike Gravel",
        //   designation: "Former US Senator",
        //   desktopImage: "images/webslides/7.jpg",
        //   // desktopImage: "images/Artboard1.png",
        //   mobileImage: "images/mobile_images/mike_gravel.png",
        //   textRight: true,
        // },
        // {
        //   quote: "Dr Aafia's case is an fantasy created by the FBI.",
        //   saidBy: "Yvonne Ridley",
        //   designation: "British Journalist",
        //   desktopImage: "images/webslides/8.jpg",
        //   // desktopImage: "images/Artboard2.png",
        //   mobileImage: "images/mobile_images/Ridley image-2.png",
        //   textRight: false,
        // },
        // {
        //   quote:
        //     "I haven't witnessed such bare injustice in my entire career. Neither did she kill, nor did she attempt at. In fact, she was shot thrice. It was an open violation of international laws. I regret that the U.S violated the rules",
        //   saidBy: "Ramsey Clark",
        //   designation: "Former U.S Attorney General",
        //   desktopImage: "images/webslides/9.jpg",
        //   // desktopImage: "images/Artboard3.png",
        //   mobileImage: "images/mobile_images/ramsey.png",
        //   textRight: false,
        // },
      ],
      videoData: [
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=-wbvNCsZP9g?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=-wbvNCsZP9g?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/-wbvNCsZP9g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F-wbvNCsZP9g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D-wbvNCsZP9g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F-wbvNCsZP9g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=7fMd_2rwVuw?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=7fMd_2rwVuw?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/7fMd_2rwVuw/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7fMd_2rwVuw%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7fMd_2rwVuw&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7fMd_2rwVuw%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },

            // my obj
            // {
            //   url: "https://i.ytimg.com/vi/-wbvNCsZP9g/hqdefault.jpg",
            //   originalUrl: "https://www.youtube.com/embed/-wbvNCsZP9g?autoplay=1",
            //   type: "video",
            // },
            // {
            //   url: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
            //   originalUrl: "https://www.youtube.com/embed/YvAGpWds8D8?autoplay=1",
            //   type: "video",
            // },
            // {
            //   url: "https://i.ytimg.com/vi/7fMd_2rwVuw/hqdefault.jpg",
            //   originalUrl: "https://www.youtube.com/embed/7fMd_2rwVuw?autoplay=1",
            //   type: "video",
            // },
          ],
          group: "Explainer",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vnItnUz8B-k/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvnItnUz8B-k%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvnItnUz8B-k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvnItnUz8B-k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vmaM7T1AQDU/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvmaM7T1AQDU%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvmaM7T1AQDU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvmaM7T1AQDU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/zp7LOtD9wyE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fzp7LOtD9wyE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dzp7LOtD9wyE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fzp7LOtD9wyE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4rKkyivME8w?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=4rKkyivME8w?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4rKkyivME8w/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4rKkyivME8w%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4rKkyivME8w&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4rKkyivME8w%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Podcasts",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=3blBpH1OEZA?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=3blBpH1OEZA?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/3blBpH1OEZA/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F3blBpH1OEZA%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D3blBpH1OEZA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F3blBpH1OEZA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=y-p-MRTwang?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=y-p-MRTwang?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/y-p-MRTwang/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fy-p-MRTwang%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dy-p-MRTwang&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fy-p-MRTwang%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=pwC2iRxTIhE?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=pwC2iRxTIhE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/pwC2iRxTIhE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FpwC2iRxTIhE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DpwC2iRxTIhE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FpwC2iRxTIhE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Events",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=vnItnUz8B-k?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vnItnUz8B-k/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvnItnUz8B-k%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvnItnUz8B-k&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvnItnUz8B-k%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WxZ5qVBoHL4?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=WxZ5qVBoHL4?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WxZ5qVBoHL4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWxZ5qVBoHL4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxZ5qVBoHL4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWxZ5qVBoHL4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=vmaM7T1AQDU?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/vmaM7T1AQDU/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvmaM7T1AQDU%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvmaM7T1AQDU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvmaM7T1AQDU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Clive",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/weh4_ICBPPo/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fweh4_ICBPPo%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dweh4_ICBPPo&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fweh4_ICBPPo%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              originalUrl: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/1oAFs8UR5ow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F1oAFs8UR5ow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1oAFs8UR5ow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F1oAFs8UR5ow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Rc5tRN5PId0",
              originalUrl: "https://www.youtube.com/watch?v=Rc5tRN5PId0",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rc5tRN5PId0/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRc5tRN5PId0%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRc5tRN5PId0&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRc5tRN5PId0%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WxZ5qVBoHL4",
              originalUrl: "https://www.youtube.com/watch?v=WxZ5qVBoHL4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WxZ5qVBoHL4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWxZ5qVBoHL4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWxZ5qVBoHL4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWxZ5qVBoHL4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=q8e5T5Fc_jA",
              originalUrl: "https://www.youtube.com/watch?v=q8e5T5Fc_jA",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/q8e5T5Fc_jA/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fq8e5T5Fc_jA%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dq8e5T5Fc_jA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fq8e5T5Fc_jA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=qVp32goZHuM",
              originalUrl: "https://www.youtube.com/watch?v=qVp32goZHuM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/qVp32goZHuM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqVp32goZHuM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqVp32goZHuM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqVp32goZHuM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "TCM Series",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=TxHJ0IyKZ2Q?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/TxHJ0IyKZ2Q/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FTxHJ0IyKZ2Q%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DTxHJ0IyKZ2Q&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FTxHJ0IyKZ2Q%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4bbEGxQgzSE?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=4bbEGxQgzSE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4bbEGxQgzSE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4bbEGxQgzSE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4bbEGxQgzSE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4bbEGxQgzSE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Documentaries",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/eOnDBOC5TxQ/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FeOnDBOC5TxQ%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeOnDBOC5TxQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FeOnDBOC5TxQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=weh4_ICBPPo?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/weh4_ICBPPo/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fweh4_ICBPPo%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dweh4_ICBPPo&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fweh4_ICBPPo%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              originalUrl: "https://www.youtube.com/watch?v=1oAFs8UR5ow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/1oAFs8UR5ow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F1oAFs8UR5ow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D1oAFs8UR5ow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F1oAFs8UR5ow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },

            {
              url: "https://www.youtube.com/watch?v=qVp32goZHuM",
              originalUrl: "https://www.youtube.com/watch?v=qVp32goZHuM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/qVp32goZHuM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FqVp32goZHuM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DqVp32goZHuM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FqVp32goZHuM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              originalUrl: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/fkd76_0JnZY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Ffkd76_0JnZY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dfkd76_0JnZY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Ffkd76_0JnZY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              originalUrl: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/ZtlySlf0KC4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZtlySlf0KC4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZtlySlf0KC4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZtlySlf0KC4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=zp7LOtD9wyE?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/zp7LOtD9wyE/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fzp7LOtD9wyE%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dzp7LOtD9wyE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fzp7LOtD9wyE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Dr Fozia",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              originalUrl: "https://www.youtube.com/watch?v=fkd76_0JnZY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/fkd76_0JnZY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Ffkd76_0JnZY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dfkd76_0JnZY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Ffkd76_0JnZY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=eOnDBOC5TxQ?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/eOnDBOC5TxQ/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FeOnDBOC5TxQ%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DeOnDBOC5TxQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FeOnDBOC5TxQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              originalUrl: "https://www.youtube.com/watch?v=ZtlySlf0KC4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/ZtlySlf0KC4/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FZtlySlf0KC4%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DZtlySlf0KC4&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FZtlySlf0KC4%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=O6KaSYJg-KY",
              originalUrl: "https://www.youtube.com/watch?v=O6KaSYJg-KY",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/O6KaSYJg-KY/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FO6KaSYJg-KY%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DO6KaSYJg-KY&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FO6KaSYJg-KY%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              originalUrl: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/EguvYXEI0tM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEguvYXEI0tM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEguvYXEI0tM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEguvYXEI0tM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=WBDC_ZZiZpw",
              originalUrl: "https://www.youtube.com/watch?v=WBDC_ZZiZpw",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/WBDC_ZZiZpw/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FWBDC_ZZiZpw%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DWBDC_ZZiZpw&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FWBDC_ZZiZpw%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=GdLgb05uYNI",
              originalUrl: "https://www.youtube.com/watch?v=GdLgb05uYNI",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/GdLgb05uYNI/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FGdLgb05uYNI%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DGdLgb05uYNI&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FGdLgb05uYNI%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=r-eI7umWBc8",
              originalUrl: "https://www.youtube.com/watch?v=r-eI7umWBc8",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/r-eI7umWBc8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fr-eI7umWBc8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dr-eI7umWBc8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fr-eI7umWBc8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              originalUrl: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4RF5mjMGm7g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RF5mjMGm7g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RF5mjMGm7g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RF5mjMGm7g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=XX4fBhduF2c",
              originalUrl: "https://www.youtube.com/watch?v=XX4fBhduF2c",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/XX4fBhduF2c/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FXX4fBhduF2c%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DXX4fBhduF2c&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FXX4fBhduF2c%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=7TNCxMkkOow",
              originalUrl: "https://www.youtube.com/watch?v=7TNCxMkkOow",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/7TNCxMkkOow/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F7TNCxMkkOow%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D7TNCxMkkOow&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F7TNCxMkkOow%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "News Channels",
        },
        {
          items: [
            {
              url: "https://www.youtube.com/watch?v=fyFw7lDS_Ys?autoplay=1",
              originalUrl: "https://www.youtube.com/watch?v=fyFw7lDS_Ys?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "http://i.ytimg.com/vi/fyFw7lDS_Ys/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FfyFw7lDS_Ys%3Fautoplay%3D1&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DfyFw7lDS_Ys&image=http%3A%2F%2Fi.ytimg.com%2Fvi%2FfyFw7lDS_Ys%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              originalUrl: "https://www.youtube.com/watch?v=EguvYXEI0tM",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/EguvYXEI0tM/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEguvYXEI0tM%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEguvYXEI0tM&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEguvYXEI0tM%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=wWWa9MRIV50",
              originalUrl: "https://www.youtube.com/watch?v=wWWa9MRIV50",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/wWWa9MRIV50/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FwWWa9MRIV50%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DwWWa9MRIV50&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FwWWa9MRIV50%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
            {
              url: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              originalUrl: "https://www.youtube.com/watch?v=4RF5mjMGm7g",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/4RF5mjMGm7g/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F4RF5mjMGm7g%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D4RF5mjMGm7g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F4RF5mjMGm7g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "Imran Khan",
        },
      ],
      appealData: [
        {
          title: "Ramadan Projects & Eid Gifts",
          category: "ramadan-projects",
          image: require("@/assets/images/worldappeal/flyer/Layer 3.jpg"),
          content:
            "Education is a key that unlocks many opportunities. It is a way out of poverty; a driving force for social change; and an important aspect of personal development and welfare. This is why it is one of the main area’s of focus for us here at World Appeal.",
        },
        // {
        //   title: "Emergency Relief in Middle East",
        //   category: "emergency-relief",
        //   image: require("@/assets/images/worldappeal/flyer/Layer 8.jpg"),
        //   content:
        //     "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        // },
        {
          title: "Water Projects",
          category: "water-projects",
          image: require("@/assets/images/worldappeal/flyer/Layer 2.jpg"),
          content:
            "Water is a basic necessity for life, unfortunately, in 2021 many people spend their entire day searching for clean drinking water and in some cases are unsuccessful and have to use contaminated water sources. Help us install wells and pumps.",
        },
        {
          title: "Child Sponsorship",
          category: "child-sponsorship",
          image: require("@/assets/images/worldappeal/flyer/Layer 5.jpg"),
          content:
            "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        },
        {
          title: "Mosque Projects",
          category: "mosque-projects",
          image: require("@/assets/images/worldappeal/flyer/Layer 32.jpg"),
          content:
            "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        },
      ],
      currentProject: "",
      currentData: [],
      currentItem: {},
      subData: [
        {
          title: "Fitrana",
          image: require("@/assets/images/worldappeal/flyer/Layer 3.jpg"),
          content:
            "It is the responsibility of the head of each household pay £4 for every member in the household before Eid-ul-Fitr Prayer.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/bIY8wVa4S8IfaWI289",
          value: 1,
          amount: 4,
        },
        {
          title: "Fidayah (£4 for every fast missed)",
          image: require("@/assets/images/worldappeal/flyer/Layer 4.jpg"),
          content:
            "If any person is medically exempt from fasting through-out the month of Ramadan and he/she is unable to make up for the missed fasts, then they must pay Fidayah for the entire month.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/bIY28x3Gu7Ebc0MaET",
          value: 1,
          amount: 4,
        },
        {
          title: "Sahoor and Iftar meals - (£60 for entire month)",
          image: require("@/assets/images/worldappeal/flyer/Layer 6.jpg"),
          content: "Feed a fasting person two meals throughout the month of ramadan.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/28o28xb8W1fNd4QcN0",
          value: 1,
          amount: 2,
        },
        {
          title: "Ramadan Food Parcel - (£50 for family of 5)",
          image: require("@/assets/images/worldappeal/flyer/Layer 8.jpg"),
          content:
            "Delivering essential food parcels to support a family of 5 in need for the entire month of Ramadan.",
          category: "ramadan-projects",
          link: "https://buy.stripe.com/fZe7sR2Cqe2zfcY28o",
          value: 1,
          amount: 50,
        },
        {
          title: "Sahoor and Iftar Meals - £60",
          image: require("@/assets/images/worldappeal/flyer/Layer 7.jpg"),
          content: "Provide daily meals for 1 person for Sahoor and Iftar throughout the entire month of Ramadan.",
          category: "emergency-relief",
          link: "https://buy.stripe.com/aEU14t7WKe2zd4QcMM",
          value: 1,
          amount: 4,
        },
        {
          title: "Emergency Aid - £100",
          image: require("@/assets/images/worldappeal/flyer/meals.jpg"),
          content:
            "Delivering vital emergency aid wherever the need is most, including food, water and basic essentials.",
          category: "emergency-relief",
          link: "https://buy.stripe.com/aEU14t7WKe2zd4QcMM",
          value: 1,
          amount: 4,
        },
        {
          title: "Small Hand Pump - £150",
          image: require("@/assets/images/worldappeal/flyer/Layer 2.jpg"),
          content: "Provide a community of 50 people with clean and safe drinking water in Asia.",
          category: "water-projects",
          link: "https://buy.stripe.com/4gwbJ7dh49Mj2qc28c",
          value: 1,
          amount: 150,
        },
        {
          title: "Medium Water Pump - £450",
          image: require("@/assets/images/worldappeal/flyer/Layer 11.jpg"),
          content: "Build a water pump to provide water for more than 150 people.",
          category: "water-projects",
          link: "https://buy.stripe.com/eVaaF3el8gaH1m8fZ3",
          value: 1,
          amount: 450,
        },
        {
          title: "Large Water Well - £3000",
          image: require("@/assets/images/worldappeal/flyer/water-story.png"),
          content: "Build a water well for entire village in Africa.",
          category: "water-projects",
          link: "https://buy.stripe.com/dR66oN3Gue2z8OA006",
          value: 1,
          amount: 3000,
        },
        {
          title: "Orphan and Needy - £35 per month and £420 per year",
          image: require("@/assets/images/worldappeal/flyer/Layer 5.jpg"),
          content:
            "Provide monthly support to an orphan and needy child with education, clothing, food and living expenses.",
          category: "child-sponsorship",
          link: "https://worldappeal.enthuse.com/orphan",
          value: 1,
          amount: 35,
          showMore: true,
          moreItems: [
            { title: "Donate one time (£35)", link: "https://buy.stripe.com/3cs4gFcd08If1m828f" },
            { title: "Donate one time (£420)", link: "https://buy.stripe.com/7sIeVj90O7Ebc0MbIQ" },
            { title: "Donate every month", link: "https://worldappeal.enthuse.com/orphan" },
          ],
        },
        {
          title: "Hifz e Quran - £25 per month and £300 per year",
          image: require("@/assets/images/worldappeal/flyer/Layer 61.jpg"),
          content: "Support a child to memorise the holy quran, this is a 4 year program.",
          category: "child-sponsorship",
          link: "https://worldappeal.enthuse.com/Hifz",
          value: 1,
          amount: 25,
          showMore: true,
          moreItems: [
            { title: "Donate one time (£25)", link: "https://buy.stripe.com/14k5kJcd03nV4yk6oy" },
            { title: "Donate one time (£300)", link: "https://buy.stripe.com/7sI8wVel83nVd4Q14f" },
            { title: "Donate every month", link: "https://worldappeal.enthuse.com/Hifz" },
          ],
        },
        {
          title: "Eid Gift - £15",
          image: require("@/assets/images/worldappeal/flyer/Layer 9.jpg"),
          content: "Provide a child in need with an Eid Gift (food, clothes and toys).",
          category: "child-sponsorship",
          link: "https://buy.stripe.com/fZe7sR5OCcYv3ugeV3",
          value: 1,
          amount: 15,
        },
        {
          title: "Small Mosque - £6000",
          image: require("@/assets/images/worldappeal/flyer/Layer 32.jpg"),
          content: "Build a mosque for 140 people to worship.",
          category: "mosque-projects",
          link: "https://buy.stripe.com/4gw8wV90O2jRaWIbIU",
          value: 1,
          amount: 6000,
        },
        {
          title: "Medium Mosque - £7500",
          image: require("@/assets/images/worldappeal/flyer/medium-mosque.jpeg"),
          content: "Build a mosque for 230 people to worship and learn about deen.",
          category: "mosque-projects",
          link: "https://buy.stripe.com/9AQ9AZ3Gu4rZ2qc28l",
          value: 1,
          amount: 7500,
        },
      ],

      articles: [
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 21, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
        {
          date: "May 11, 2012",
          title: "Dr Aafia’s lawyer urges more efforts for her return",
          image: "images/aafia.jpg",
        },
      ],
      storyData: [
        // {
        //   title: "News",
        //   items: [
        //     {
        //       url: "images/news/92.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Jehan e pakistan.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ummat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Dunya Express.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ideal Pakistan.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/press release urdu.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/press release english.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Daily Parliaments.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/nai Baat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Express and jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Dunya Ausaf.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/92 And Nawai Waqt.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Press Release Urdu(1).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Press releaswe English.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.23 PM (2).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.24 PM (1).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.23 PM (3).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/REF NO 051 PR 16 AUG 2023 IHC.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/REF NO 051 PR 16 AUG 2023 ENG IHC.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Nai Baat & Jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/daily parliament.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/REF NO 052 PR 18 AUG 2023 IHC.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Bureaucratic hurdles delaying Aafia Siddiqui case, says lawyer.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-08-19 at 11.38.10 AM.jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ausaf & jasarat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Jehan e pakistan & Nai baat.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.25 PM (1).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.25 PM (2).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/daily parliament(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Jehan e pakistan(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Ausaf.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/92(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Express.jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Nai Baat(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (2).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (3).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/ummat(1).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/daily parliament(2).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/Nai Baat(2).jpg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (4).jpeg",
        //       type: "image",
        //     },
        //     {
        //       url: "images/news/WhatsApp Image 2023-09-02 at 3.30.28 PM (5).jpeg",
        //       type: "image",
        //     },
        //   ],
        // },

        {
          title: "Videos",
          items: [
            {
              url: "https://cdn.cloudious.net/file-1690805918217-816027894.jpg",
              originalUrl: "images/stories/video1.mp4",
              thumbnailUrl: "https://cdn.cloudious.net/file-1690805918217-816027894.jpg",
              html: '<iframe class="embedly-embed" src="images/stories/video1.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
            {
              url: "images/stories/thumb2.jpeg",
              originalUrl: "images/stories/video2.mp4",
              thumbnailUrl: "images/stories/thumb2.jpeg",
              html: '<iframe class="embedly-embed" src="images/stories/video2.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
            {
              url: "images/stories/thumb3.jpeg",
              originalUrl: "images/stories/video3.mp4",
              thumbnailUrl: "images/stories/thumb3.jpeg",
              html: '<iframe class="embedly-embed" src="images/stories/video3.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
            {
              url: "images/stories/thumb4.jpeg",
              originalUrl: "images/stories/video4.mp4",
              thumbnailUrl: "images/stories/thumb4.jpeg",
              html: '<iframe class="embedly-embed" src="images/stories/video4.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
              width: 940,
              height: 528,
            },
          ],
          group: "",
        },
        {
          title: "Truth",
          items: [
            {
              _id: "example_img",
              origFileName: "a2img1.png",
              fileName: "a2img1.png",
              fileSize: 471544,
              height: 535,
              url: "images/a2img1.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img2.png",
              fileName: "a2img2.png",
              fileSize: 170347,
              height: 535,
              url: "images/a2img2.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img3.png",
              fileName: "a2img3.png",
              fileSize: 200953,
              height: 535,
              url: "images/a2img3.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img4.png",
              fileName: "a2img4.png",
              fileSize: 163527,
              height: 535,
              url: "images/a2img4.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "a2img5.png",
              fileName: "a2img5.png",
              fileSize: 301331,
              height: 535,
              url: "images/a2img5.png",
              width: 535,
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Aafia’s story",
          items: [
            {
              _id: "example_img",
              origFileName: "page1.png",
              fileName: "page1.png",
              fileSize: 331411,
              height: 732,
              url: "images/page1.png",
              width: 592,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page2.png",
              fileName: "page2.png",
              fileSize: 329583,
              height: 727,
              url: "images/page2.png",
              width: 602,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page3.png",
              fileName: "page3.png",
              fileSize: 361300,
              height: 706,
              url: "images/page3.png",
              width: 581,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page4.png",
              fileName: "page4.png",
              fileSize: 340994,
              height: 708,
              url: "images/page4.png",
              width: 585,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page5.png",
              fileName: "page5.png",
              fileSize: 374048,
              height: 709,
              url: "images/page5.png",
              width: 594,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page6.png",
              fileName: "page6.png",
              fileSize: 307265,
              height: 685,
              url: "images/page6.png",
              width: 590,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page7.png",
              fileName: "page7.png",
              fileSize: 326497,
              height: 703,
              url: "images/page7.png",
              width: 593,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page8.png",
              fileName: "page8.png",
              fileSize: 251961,
              height: 704,
              url: "images/page8.png",
              width: 595,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page9.png",
              fileName: "page9.png",
              fileSize: 314054,
              height: 708,
              url: "images/page9.png",
              width: 611,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page10.png",
              fileName: "page10.png",
              fileSize: 240401,
              height: 695,
              url: "images/page10.png",
              width: 593,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page11.png",
              fileName: "page11.png",
              fileSize: 308241,
              height: 692,
              url: "images/page11.png",
              width: 579,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page12.png",
              fileName: "page12.png",
              fileSize: 298245,
              height: 703,
              url: "images/page12.png",
              width: 576,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page13.png",
              fileName: "page13.png",
              fileSize: 302759,
              height: 701,
              url: "images/page13.png",
              width: 572,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page14.png",
              fileName: "page14.png",
              fileSize: 338755,
              height: 742,
              url: "images/page14.png",
              width: 613,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page15.png",
              fileName: "page15.png",
              fileSize: 331493,
              height: 704,
              url: "images/page15.png",
              width: 583,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page16.png",
              fileName: "page16.png",
              fileSize: 269092,
              height: 693,
              url: "images/page16.png",
              width: 568,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page17.png",
              fileName: "page17.png",
              fileSize: 303814,
              height: 685,
              url: "images/page17.png",
              width: 567,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page18.png",
              fileName: "page18.png",
              fileSize: 295530,
              height: 726,
              url: "images/page18.png",
              width: 605,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "page19.png",
              fileName: "page19.png",
              fileSize: 284178,
              height: 728,
              url: "images/page19.png",
              width: 596,
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Myths vs Facts",
          items: [
            {
              url: "images/stories/myth1.png",
              type: "image",
            },
            {
              url: "images/stories/myth2.png",
              type: "image",
            },
            {
              url: "images/stories/myth3.png",
              type: "image",
            },
            {
              url: "images/stories/myth4-1.png",
              type: "image",
            },
            {
              url: "images/stories/myth4-2.png",
              type: "image",
            },
            {
              url: "images/stories/myth5.png",
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Testimonials",
          items: [
            {
              url: "images/stories/1.png",
              type: "image",
            },
            {
              url: "images/stories/2.png",
              type: "image",
            },
            {
              url: "images/stories/3.png",
              type: "image",
            },
            {
              url: "images/stories/4.png",
              type: "image",
            },
            {
              url: "images/stories/5.png",
              type: "image",
            },
            {
              url: "images/stories/6.png",
              type: "image",
            },
            {
              url: "images/stories/7.png",
              type: "image",
            },
            {
              url: "images/stories/8.png",
              type: "image",
            },
            {
              url: "images/stories/9.png",
              type: "image",
            },
            {
              url: "images/stories/10.png",
              type: "image",
            },
            {
              url: "images/stories/11.png",
              type: "image",
            },
            {
              url: "images/stories/12.png",
              type: "image",
            },
            {
              url: "images/stories/13.png",
              type: "image",
            },
            {
              url: "images/stories/14.png",
              type: "image",
            },
            {
              url: "images/stories/15.png",
              type: "image",
            },
            {
              url: "images/stories/16.png",
              type: "image",
            },
            {
              url: "images/stories/17.png",
              type: "image",
            },
            {
              url: "images/stories/18.png",
              type: "image",
            },
            {
              url: "images/stories/19.png",
              type: "image",
            },
            {
              url: "images/stories/20.png",
              type: "image",
            },
            {
              url: "images/stories/21.png",
              type: "image",
            },
            {
              url: "images/stories/22.png",
              type: "image",
            },
            {
              url: "images/stories/23.png",
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Clive S Smith",
          items: [
            {
              url: "images/stories/3.png",
              type: "image",
            },
            {
              url: "images/stories/4.png",
              type: "image",
            },
            {
              url: "images/stories/5.png",
              type: "image",
            },
            {
              url: "images/stories/6.png",
              type: "image",
            },
            {
              url: "images/stories/7.png",
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "Yvonne’s Discovery",
          items: [
            {
              _id: "example_img",
              origFileName: "img9.png",
              fileName: "img9.png",
              fileSize: 496576,
              height: 535,
              url: "images/img9.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img10.png",
              fileName: "img10.png",
              fileSize: 195337,
              height: 535,
              url: "images/img10.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img8.png",
              fileName: "img8.png",
              fileSize: 191955,
              height: 535,
              url: "images/img8.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img7.png",
              fileName: "img7.png",
              fileSize: 168982,
              height: 535,
              url: "images/img7.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img6.png",
              fileName: "img6.png",
              fileSize: 199232,
              height: 535,
              url: "images/img6.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img5.png",
              fileName: "img5.png",
              fileSize: 183057,
              height: 535,
              url: "images/img5.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img4.png",
              fileName: "img4.png",
              fileSize: 175604,
              height: 535,
              url: "images/img4.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img3.png",
              fileName: "img3.png",
              fileSize: 190593,
              height: 535,
              url: "images/img3.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img1.png",
              fileName: "img1.png",
              fileSize: 202896,
              height: 535,
              url: "images/img1.png",
              width: 535,
              type: "image",
            },
            {
              _id: "example_img",
              origFileName: "img2.png",
              fileName: "img2.png",
              fileSize: 218930,
              height: 535,
              url: "images/img2.png",
              width: 535,
              type: "image",
            },
          ],
          group: "",
        },
        {
          title: "My Sister Aafia",
          items: [
            {
              url: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              // originalUrl: "https://www.youtube.com/watch?v=Dr_qzvyUwqc?autoplay=1",
              originalUrl: "https://www.youtube.com/embed/Dr_qzvyUwqc?autoplay=1",
              // originalUrl: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Dr_qzvyUwqc/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FDr_qzvyUwqc%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DDr_qzvyUwqc&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FDr_qzvyUwqc%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "",
        },
        {
          title: "Aafia Siddiqui’s plight",
          items: [
            {
              url: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              // originalUrl: "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
              originalUrl: "https://www.youtube.com/embed/YvAGpWds8D8?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "",
        },
        {
          title: "Where is Dr. Aafia?",
          items: [
            {
              url: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              // originalUrl: "https://www.youtube.com/watch?v=Rg6L4yUlnbs?autoplay=1",
              originalUrl: "https://www.youtube.com/embed/Rg6L4yUlnbs?autoplay=1",
              width: 940,
              height: 528,
              thumbnailUrl: "https://i.ytimg.com/vi/Rg6L4yUlnbs/hqdefault.jpg",
              html: '<iframe class="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRg6L4yUlnbs%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRg6L4yUlnbs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRg6L4yUlnbs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
              type: "video",
            },
          ],
          group: "",
        },
        // {
        //   title: "Videos",
        //   items: [
        //     {
        //       url: "https://i.ytimg.com/vi/6UJTswEmR-c/hqdefault.jpg",
        //       originalUrl: "images/stories/test.mp4",
        //       thumbnailUrl: "https://i.ytimg.com/vi/6UJTswEmR-c/hqdefault.jpg",
        //       html: '<iframe class="embedly-embed" src="images/stories/test.mp4" width="940" height="528" scrolling="no" title="video" frameborder="0" allow="autoplay; fullscreen" allowfullscreen="true"></iframe>',
        //       type: "video",
        //       width: 940,
        //       height: 528,
        //     },
        //   ],
        //   group: "",
        // },
      ],

      first: true,
      targetElement: null,
      noticeData: {
        items: [
          // {
          //   url: "images/notice/daughter-of-nation1.jpg",
          //   type: "image",
          // },
          // {
          //   url: "images/notice/sister1.jpg",
          //   type: "image",
          // },
          // {
          //   url: "images/notice/aafia-calls-nation.jpg",
          //   type: "image",
          // },
          // {
          //   url: "images/notice/aafia-calls-you.jpg",
          //   type: "image",
          // },
          {
            url: "images/notice/notice.jpeg",
            type: "image",
          },
        ],
        group: "notice",
      },
      noticeTill: "2023-09-24",
    };
  },
  watch: {
    $route: function () {
      this.setSub();
    },
  },
  mounted() {
    // console.log(this.showDots());
    window.addEventListener("resize", this.handleResize);

    // this.setStories();
    // this.setPara();

    // this.loadStories();

    // this.setSLightbox();

    // this.videoData.forEach((el, ind) => {
    //   let script = document.createElement("script");
    //   script.type = "application/json";
    //   script.classList.add("w-json");

    //   script.text = JSON.stringify(el);

    //   const target = document.getElementById(`video-${ind + 1}`);
    //   target.parentNode.insertBefore(script, target.nextSibling);
    // });

    window.addEventListener("popstate", function (event) {
      // console.log("Previous state: ", event);
      const cross = document.querySelector(".w-lightbox-close");
      if (cross) {
        cross.click();
      }
      // window.history.replaceState(null, null, window.location.pathname);
    });
  },
  created() {
    // this.loadData();

    // this.loadStories();
    // if (this.$store.state.slides.length == 0) {
    //   this.$store.commit("setSlides", this.slideData);
    // }
    this.$store.commit("setSlides", this.slideData);
    this.setSub();
    // this.loadSlides();
    // console.log(window.history, window.location);
  },
  computed: {
    screenSize() {
      return this.windowSize <= 479;
      // if (window.innerWidth <= 479) {
      //   if (this.articles.length > 4) this.isExpanded = true;
      //   else this.isExpanded = false;
      //   return this.articles.slice(0, 4);
      // } else {
      //   return this.articles;
      // }
    },
    showDots() {
      const size = this.windowSize;
      console.log(size);
      if (size > 991) {
        if (this.news.length > 4) return true;
        else return false;
      } else if (size > 796) {
        if (this.news.length > 3) return true;
        else return false;
      } else if (size > 479) {
        if (this.news.length > 2) return true;
        else return false;
      } else if (size <= 479) {
        if (this.news.length > 1) return true;
        else return false;
      } else return false;
    },
  },
  methods: {
    openLightbox(items) {
      this.lightboxData = items;
      this.showLightbox = true;
      setTimeout(() => {
        this.$refs.main.sync(this.$refs.thumbnails.splide);
        // stop all autoplay except for first
        let allTags = document.querySelectorAll(".lightbox-iframe");
        allTags.forEach((el, ind) => {
          if (ind != 0) el.src = "";
        });
      }, 200);
    },
    handleMove(e, newIndex, prevIndex) {
      let prevTag = document.getElementById(`iframe${prevIndex}`);
      if (prevTag) {
        prevTag.src = "";
      }
      let nextTag = document.getElementById(`iframe${newIndex}`);
      if (nextTag) {
        nextTag.src = this.lightboxData[newIndex].originalUrl;
      }
    },
    onHide() {
      this.visibleRef = false;
    },
    show() {
      this.visibleRef = true;
    },
    setBox() {
      window.history.pushState(null, null, window.location.pathname);

      // console.log(window.history);
      // setTimeout(() => {
      // if (this.first) {
      //   this.createOb();
      //   this.first = false;
      // }
      // }, 200);
    },
    createOb() {
      // Function to check if element is a valid DOM element
      const isValidElement = (element) => {
        return element instanceof Element || element instanceof HTMLDocument;
      };

      // Target element you want to observe
      this.targetElement = document.querySelector(".w-lightbox-backdrop");
      // Check if the targetElement is valid
      if (isValidElement(this.targetElement)) {
        // Callback function to be executed when the element's opacity changes

        const observer = new MutationObserver(this.opacityChangedCallback);
        const config = { attributes: true };

        // Start observing the target element
        observer.observe(this.targetElement, config);
      } else {
        console.error("Invalid target element or not found in the DOM.");
      }
    },
    opacityChangedCallback(mutationsList, observer) {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === "style") {
          // Check if the element's opacity is 1 (hidden)
          const currentOpacity = parseFloat(this.targetElement.style.opacity);
          if (currentOpacity === 0) {
            // Run your function here when the element's opacity is 1
            window.history.pushState(null, null, window.location.pathname);
            console.log(window.history);
          }
        }
      }
    },
    handleResize() {
      this.windowSize = window.innerWidth;
      // console.log("ss");
    },
    setSub() {
      let obj = this.appealData.find((el) => el.category == this.$route.query.project);
      // console.log(obj);
      if (obj) {
        this.currentData = this.subData.filter((el) => el.category == obj.category);
        this.currentProject = obj.title;
      } else {
        this.removeSub();
      }
      // this.setBox();
    },
    filterRoute(cat) {
      return {
        path: this.$route.path,
        query: { project: cat },
      };
    },
    removeSub() {
      this.currentProject = "";
      this.currentData = [];
    },
    setStories() {
      if (window.innerWidth <= 479) {
        const blockA = document.querySelector(".storydiv");
        const section5 = document.getElementById("main-slider");
        section5.parentNode.insertBefore(blockA, section5);
      }
    },
    setPara() {
      const element = document.querySelector(".leftparagraph");
      const originalHeight = element.offsetHeight;
      element.classList.add("text-line-clamp");
      const lineClampHeight = element.offsetHeight;

      // console.log(element, originalHeight, lineClampHeight);
      this.showRead = lineClampHeight <= originalHeight; //  true line-clamp applied
    },
    setSLightbox() {
      // console.log(this.storyData);
      this.storyData.forEach((el, ind) => {
        // Create a new script element
        let script = document.createElement("script");
        script.type = "application/json";
        script.classList.add("w-json");
        // Set the content of the script element
        script.text = JSON.stringify(el);

        const target = document.getElementById(`story-${ind + 1}`);
        target.parentNode.insertBefore(script, target.nextSibling);
      });
    },
    filteredItems(ind) {
      return this.articles.slice((ind - 1) * 4, ind * 4);
    },

    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";
    },
    toggleOptions(text, item) {
      const sec = document.querySelector("#donate-options");
      this.currentItem = item;
      sec.style.display = text == "open" ? "flex" : "none";
    },
    openDialog(item) {
      if (item.value == 0 || item.value % 1 !== 0) {
        this.toast.error("Please enter correct value");
      } else if (item.value > 999) {
        this.toast.error("Quantity exceeds maximum limit of 999. Please adjust the quantity.");
      } else {
        this.displayItem = item;
        this.displayBasic = true;
      }
    },
    checkValue(item) {
      // console.log(item.value);
      if (item.value == 0 || item.value % 1 !== 0) {
        return "£0";
      }
      return `£${item.amount * item.value}`;
    },
    formSubmit(e) {
      console.log(e);
    },

    getThumbnail(link) {
      const id = link.split("v=")[1];
      return `https://img.youtube.com/vi/${id}/mqdefault.jpg`;
    },

    loadData() {
      // console.log(this.$store.state.articles);
      // console.log(this.$store.state.news);
      this.loading = true;

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/News",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.news = response.data.data;
          this.news.sort(function (a, b) {
            return new Date(b.date) - new Date(a.date);
          });
          this.$store.commit("setNews", this.news);
          this.loading = false;
          // console.log(this.$store.state.news);
        })
        .catch(function (error) {
          // console.log(error);
        });

      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Articles",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.articles = response.data.data.reverse();
          this.$store.commit("setArticles", this.articles);
          // console.log(this.$store.state.articles);
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    loadSlides() {
      var config2 = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Slider",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config2)
        .then((response) => {
          this.slideData = response.data.data;
          this.$store.commit("setSlides", this.slideData);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadStories() {
      var config = {
        method: "get",
        url: "https://aafia.cloudious.net/api/Stories",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };

      axios(config)
        .then((response) => {
          let res = response.data.data;
          // console.log(this.items);
          this.storyData = res.map((el) => {
            return {
              items: JSON.parse(el.images),
              // items: [{ url: "images/notice/notice.jpeg", type: "image" }],
              title: el.title,
            };
          });
          // console.log(this.storyData);
          console.log(this.storyData);
          // this.setSLightbox();
          // this.storyData.forEach((el, ind) => {
          //   // Create a new script element
          //   let script = document.createElement("script");
          //   script.type = "application/json";
          //   script.classList.add("w-json");
          //   console.log(el);
          //   // Set the content of the script element
          //   script.text = JSON.stringify(el);

          //   const target = document.getElementById(`story-${ind + 1}`);
          //   target.parentNode.insertBefore(script, target.nextSibling);
          // });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- <script type="application/json" class="w-json">
{
  "items": [
    {
      "url": "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
      "originalUrl": "https://www.youtube.com/watch?v=YvAGpWds8D8?autoplay=1",
      "width": 940,
      "height": 528,
      "thumbnailUrl": "https://i.ytimg.com/vi/YvAGpWds8D8/hqdefault.jpg",
      "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FYvAGpWds8D8%3Fautoplay%3D1%26feature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DYvAGpWds8D8&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FYvAGpWds8D8%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&autoplay=1&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
      "type": "video"
    }
  ],
  "group": "case-videos"
}
</script> -->

<style lang="css">
.splide__list {
  /* transition: all 1s ease 0s; */
  transition: transform 900ms ease 0s;
}

.splide__pagination {
  bottom: unset;
  position: static;
  margin-top: 10px;
}
.splide__pagination__page {
  background-color: rgba(34, 34, 34, 0.4);
}
.splide__pagination__page.is-active {
  background: #6a2875;
  transform: none;
}

.splide__arrow {
  background: transparent;
}
.splide__arrow svg {
  fill: hsla(0, 0%, 93.3%, 0.9);
}

.storybottomslider .splide__list {
  padding-bottom: 10px !important;
}

/* ==== */
.storybottomslider .splide__slide {
  padding-inline: 5px !important;
}
.storybottomslider .splide__arrows {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0px;
}
.storybottomslider .splide__arrow svg {
  fill: #666;
}
.storybottomslider .splide__arrow:disabled svg {
  fill: #999;
}
.storybottomslider .splide__arrow {
  /* === */
  /* top: calc(50% - 18px); */
  position: static;
  transform: none;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  background: white;
  opacity: 1;
  width: 2.3rem;
  height: 2.3rem;
}

#main-slider .splide__list {
  /* === */
  /* min-height: 73vh; */
  min-height: 80vh;
}
#main-slider .splide__pagination {
  position: absolute;
  bottom: 65px;
  height: 40px;
}
#main-slider .splide__pagination__page {
  background: rgba(255, 255, 255, 0.4);
}
#main-slider .splide__pagination__page.is-active {
  background: #6a2875;
  transform: scale(1.4);
}

.my-lightbox .splide__track {
  height: 100%;
}
.my-lightbox .splide__arrow svg {
  width: 4rem;
  height: 4rem;
}
.my-lightbox-thumbnail .splide__track--nav > .splide__list > .splide__slide {
  opacity: 1;
}
.my-lightbox .splide__track--nav > .splide__list > .splide__slide.is-active {
  border-color: transparent;
  opacity: 0.3;
}

.setRight {
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  .setRight {
    margin-left: 0;
  }

  .my-lightbox .splide__arrow {
    display: none;
  }
}
@media screen and (max-width: 479px) {
  #main-slider .splide__pagination {
    bottom: 45px;
  }
}
</style>
