<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <HeaderNew :pageLoading="loader" :isSticky="stickyVal" :allPages="allPages" />
  <!-- <Header /> -->
  <router-view
    @page-loading="(val) => (loader = val)"
    @set-sticky="(val) => (stickyVal = val)"
    :allPages="allPages"
  />
  <FooterNew :allPages="allPages" />
  <!-- <Footer /> -->
</template>

<script>
import axios from "axios";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HeaderNew from "@/components/HeaderNew.vue";
import FooterNew from "@/components/FooterNew.vue";

export default {
  components: {
    Header,
    Footer,
    HeaderNew,
    FooterNew,
  },
  data() {
    return {
      loader: true,
      stickyVal: false,
      allPages: [],
    };
  },
  created() {
    this.LoadPages();
  },
  methods: {
    LoadPages() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.allPages = res.data.data;
          // console.log(this.allPages);
          // strname.replace(/\s+/g, "-").toLowerCase();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style></style>
