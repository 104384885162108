<template>
  <section class="other-section wf-section">
    <div class="container-2">
      <div v-if="loading" class="custom-loader-wrapper">
        <div class="custom-loader"></div>
      </div>
      <div class="" v-else>
        <h1 class="centered-heading">
          <span class="highlightheading">Privacy</span> Policy
        </h1>

        <div class="newscontent">
          Before using the World Appeal website or engaging in any donation
          activities, please review the following privacy policy carefully. By
          using our website, you agree to the terms outlined below.
          <br /><br />

          <h4>General Provisions</h4>
          These terms apply to all visitors and users of the World Appeal
          website and any associated services.
          <br /><br />

          <h4>Changes</h4>
          We reserve the right to update and modify these terms at any time
          without prior notice. It is your responsibility to review these terms
          regularly to stay informed of any changes.
          <br /><br />

          <h4>Use of Our Website</h4>
          You agree to use the World Appeal website in compliance with all
          applicable laws and regulations and in accordance with these terms. We
          reserve the right to deny access to our website to any user for any
          reason.<br /><br />

          <h4>Registration</h4>
          While most areas of our website are accessible without registration,
          certain activities such as donations may require registration. You
          agree to provide accurate and complete information during registration
          and to keep your login credentials secure.<br /><br />

          <h4>Privacy Statement</h4>
          By using the World Appeal website, you consent to the collection,
          storage, and processing of your information as outlined in our privacy
          statement. Please refer to our separate privacy statement for more
          details.<br /><br />

          <h4>Complaints</h4>
          If you have a complaint about any content on our website, please
          contact us at
          <a class="" href="mailto:info@worldappeal.org.uk"
            >info@worldappeal.org.uk</a
          >
          with the necessary details. Please note that we require accurate and
          complete information to address your complaint effectively.
          <br /><br />

          <h4>Intellectual Property Rights</h4>
          All content on the World Appeal website, including but not limited to
          text, images, logos, and trademarks, is owned by World Appeal or its
          licensors and is protected by copyright and other intellectual
          property laws. You may not use any content from our website without
          prior written permission.
          <br /><br />

          <h4>Disclaimers</h4>
          While we strive to provide accurate and up-to-date information, we do
          not guarantee the accuracy or completeness of the content on our
          website. We are not liable for any loss or damage resulting from the
          use of our website.
          <br /><br />

          <h4>Events Beyond Our Control</h4>
          We are not liable for any delay or failure to perform our obligations
          under these terms due to events beyond our reasonable control,
          including but not limited to acts of God, natural disasters, and
          governmental actions.
          <br /><br />

          <h4>Law and Jurisdiction</h4>
          These terms are governed by English law, and any disputes arising from
          or related to these terms will be subject to the exclusive
          jurisdiction of the English courts.
          <br /><br />

          <h4>Online Donations</h4>
          World Appeal facilitates online donations through various platforms
          such as Stripe, Enthuse, Givebrite, and Facebook. While we strive to
          ensure the security of these platforms, we disclaim any responsibility
          for errors or security breaches that may occur during the donation
          process. By making an online donation through any of these platforms,
          you agree to their respective terms of service and privacy policies.
          <br /><br />

          <h4>Billing Information Collection</h4>
          When making a donation through our website, you may be required to
          provide billing information such as your name, address, email address,
          and payment details. This information is collected securely and used
          solely for processing your donation and providing you with a receipt.
          We do not store your payment information on our servers.
          <br /><br />

          <h4>GDPR Compliance</h4>
          World Appeal is committed to protecting your privacy and complying
          with the General Data Protection Regulation (GDPR) and other
          applicable data protection laws. We only collect and process personal
          information necessary for the purposes stated in this privacy policy,
          and we ensure that your data is kept secure and confidential.
          <br /><br />
          Under the GDPR, you have the following rights regarding your personal
          information:
          <br /><br />
          <ul>
            <li>
              Right to access: You have the right to request access to the
              personal data we hold about you.
            </li>
            <li>
              Right to rectification: You have the right to request that we
              correct any inaccurate or incomplete information about you.
            </li>
            <li>
              Right to erasure: You have the right to request that we delete
              your personal data in certain circumstances.
            </li>
            <li>
              Right to restrict processing: You have the right to request that
              we restrict the processing of your personal data in certain
              circumstances.
            </li>
            <li>
              Right to data portability: You have the right to request a copy of
              your personal data in a structured, machine-readable format.
            </li>
            <li>
              Right to object: You have the right to object to the processing of
              your personal data in certain circumstances, including direct
              marketing.
            </li>
            <li>
              Rights related to automated decision making and profiling: We do
              not engage in automated decision making or profiling based on your
              personal data.
            </li>
          </ul>
          To exercise any of these rights or for any questions or concerns
          regarding the processing of your personal data, please contact us at
          <a class="" href="mailto:info@worldappeal.org.uk"
            >info@worldappeal.org.uk</a
          >. We will respond to your request in accordance with applicable data
          protection laws. <br /><br />

          <h4>Disclaimer</h4>
          While we take measures to protect your personal information, we cannot
          guarantee the security of data transmitted over the internet. By using
          our website and providing your information, you acknowledge and accept
          the inherent risks of online communication.
          <br /><br />

          <h4>Updates to Privacy Policy</h4>
          We reserve the right to update this privacy policy to reflect changes
          in our practices or legal requirements. We encourage you to review
          this policy periodically for any updates. Your continued use of our
          website after any changes to this policy will constitute your
          acceptance of those changes.
          <br /><br />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Privacy",
  data() {
    return {
      loading: false,
    };
  },
};
</script>
<style></style>
