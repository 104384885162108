<template>
  <section class="hero-stack wf-section">
    <div class="container-7">
      <div class="hero-wrapper-two custom-loader-wrapper notFound-wrapper">
        <div class="notFound-div">
          <h1 style="margin: 0">Page Not Found</h1>
          <p>The page you're looking for does not exist or have been temporarily unavailable</p>
          <!-- <a href="/" class="button-primary w-button">Go To Homepage</a> -->
          <router-link to="/" class="button-primary w-button" replace>Go To Homepage</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
};
</script>
<style></style>
