<template>
  <Pie
    ref="pie"
    id="my-chart-id"
    :data="chartData"
    :options="chartOptions"
    :height="200"
    :key="this.chartData.labels.length"
  />
</template>

<script>
import { Pie } from "vue-chartjs";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.value.label,
        datasets: [
          {
            backgroundColor: this.value.colors,
            // , "#E46651", "#00D8FF", "#DD1B16"
            data: this.value.perc,
            borderWidth: 0,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  data() {
    return {
      // chartData: {
      //   labels: ["Donation"],
      //   datasets: [
      //     {
      //       backgroundColor: ["#41B883"],
      //       // , "#E46651", "#00D8FF", "#DD1B16"
      //       data: [0],
      //       borderWidth: 0,
      //     },
      //   ],
      // },
      // chartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      // },
    };
  },
};
</script>
