<template>
  <div>
    <Splide
      id="main-slider"
      class="slider"
      :options="{
        drag: false,
        gap: 0,
        perPage: 1,
        perMove: 1,
        type: 'fade',
        arrows: false,
        pagination: false,
        autoplay: true,
        interval: 6000,
        pauseOnHover: false,
        easing: 'linear',
      }"
    >
      <SplideSlide v-for="(item, ind) in slideData" :key="ind">
        <div class="slide" style="background: linear-gradient(#6a2875, #692b74)">
          <!-- :style="{
            'background-image': 'url(' + item.desktopImage + ')',
            'background-position': item.textRight ? '10% 0' : '50% 50%',
          }" -->
          <!-- screenSize ? 'none' : -->
          <div class="slidercontainer w-container">
            <div class="slidediv" :class="{ setRight: item.textRight }">
              <h1 class="bannerHeading">Become a</h1>
              <h1 class="bannerHeading largeHeading">Fundraiser</h1>
              <h1 class="bannerHeading">For us</h1>
              <a data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2" href="#" class="button-primary w-button bannerButton"
                >Start now</a
              >
            </div>
          </div>
        </div>
      </SplideSlide>
    </Splide>

    <section class="storysection wf-section">
      <div class="container-2">
        <h1 class="centered-heading left-bordered"><span class="highlightheading">Fund Raising</span> Now</h1>
        <Splide
          class="storybottomslider"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 30,
            perPage: 3,
            perMove: 1,
            type: 'slide',
            arrows: true,
            pagination: false,
            breakpoints: {
              // 991: {
              //   perPage: 3,
              // },
              767: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(item, ind) in funds" :key="ind">
            <div class="storybottomslide">
              <a :href="item.id ? '/single-news?id=' + item.id : '#'" style="text-decoration: none">
                <div v-if="loading" style="height: 200px; padding: 20px">
                  <lv-skeleton
                    v-for="n in 8"
                    :key="n"
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                </div>
                <template v-else>
                  <!-- <div class="bottomimage image-overlay"></div> -->
                  <img :src="item.image" loading="lazy" alt="fund" class="bottomimage" />

                  <div class="bottomcontentwrapper">
                    <h4 href="#" class="fundtitle">
                      {{ item.title }}
                    </h4>
                    <div class="fund-stats">
                      <div>
                        <img src="@/assets/images/worldappeal/icons8-share-50.png" alt="" />
                        <span>{{ item.people }}</span>
                      </div>
                      <div>
                        <img src="@/assets/images/worldappeal/icons8-clock-50.png" alt="" />
                        <span>{{ item.days }} days left</span>
                      </div>
                    </div>
                    <progress :value="item.progress" max="100" class="fund-progress"></progress>
                    <div class="fund-amount-div">
                      <div>
                        <h4>${{ item.amount }}</h4>
                        <p>Funded of ${{ item.funded }}</p>
                      </div>
                      <a data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9" href="#" class="button-primary w-button"
                        >Support</a
                      >
                    </div>
                  </div>
                </template>
              </a>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </section>

    <section class="storysection gradient-section wf-section">
      <div class="container-2">
        <h1 class="centered-heading purple left-bordered">
          <span class="highlightheading-white">Tell us about your</span> Fundraising
        </h1>
        <div class="contact-form-wrapper">
          <form
            id="contact-form"
            name="contact-form"
            data-name="contact-form"
            method="get"
            class="contact-form"
            @submit.prevent="formSubmit"
          >
            <div class="contact-form-div">
              <div class="contact-form-input-group">
                <label for="name">Name</label>
                <input id="name" type="text" class="" placeholder="" required="" v-model="mailObj.name" />
              </div>
              <div class="contact-form-input-group">
                <label for="email">Email</label>
                <input id="email" type="email" class="" placeholder="" required="" v-model="mailObj.email" />
              </div>
              <div class="contact-form-input-group">
                <label for="phone">Phone</label>
                <input id="phone" type="tel" class="" placeholder="" required="" v-model="mailObj.phone" />
              </div>
            </div>
            <div class="contact-form-div">
              <div class="contact-form-input-group">
                <label for="address">Address</label>
                <input id="address" type="text" class="" placeholder="" required="" v-model="mailObj.address" />
              </div>
              <div class="contact-form-input-group">
                <label for="plans">Fundraising Plans</label>
                <textarea id="plans" class="" placeholder="" rows="6" required="" v-model="mailObj.plans"></textarea>
              </div>
            </div>
            <div class="form-button-block">
              <button type="submit" class="button-primary w-button" :disabled="request">
                <div class="custom-mini-loader" v-if="request" style="margin: auto"></div>
                <span v-else>Submit</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <section class="storysection wf-section">
      <div class="container-2">
        <h1 class="centered-heading left-bordered">
          <span class="highlightheading">World Appeal</span> Information & Blogs
        </h1>
        <Splide
          class="storybottomslider"
          :options="{
            // rewind: false,
            omitEnd: true,
            focus: 0,
            gap: 30,
            perPage: 3,
            perMove: 1,
            type: 'slide',
            arrows: true,
            pagination: false,
            breakpoints: {
              // 991: {
              //   perPage: 3,
              // },
              767: {
                perPage: 2,
              },
              479: {
                perPage: 1,
              },
            },
          }"
        >
          <SplideSlide v-for="(item, ind) in blogs" :key="ind">
            <div class="storybottomslide">
              <a :href="item.id ? '/single-news?id=' + item.id : '#'" style="text-decoration: none">
                <div v-if="loading" style="height: 200px; padding: 20px">
                  <lv-skeleton
                    v-for="n in 8"
                    :key="n"
                    :primaryOpacity="0.2"
                    :width="220"
                    :height="10"
                    primaryColor="#607c8a"
                  />
                </div>
                <template v-else>
                  <!-- <div class="bottomimage image-overlay"></div> -->
                  <img :src="item.image" loading="lazy" alt="blog" class="bottomimage" />

                  <div class="bottomcontentwrapper">
                    <h1 href="#" class="bottomtitle">
                      {{ item.title }}
                    </h1>
                    <p class="bottomparagraph">{{ item.content }}</p>
                    <a data-w-id="3792a791-ecad-aa4c-2433-89b05cfddcd9" href="#" class="button-primary w-button"
                      >Read more</a
                    >
                  </div>
                </template>
              </a>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </section>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import axios from "axios";
import LvSkeleton from "lightvue/skeleton";
import { useToast } from "vue-toastification";

export default {
  components: {
    Splide,
    SplideSlide,
    LvSkeleton,
  },
  data() {
    return {
      slideData: [
        {
          desktopImage: require("@/assets/images/worldappeal/wbanner2.png"),
          textRight: false,
        },
        {
          desktopImage: require("@/assets/images/worldappeal/wbanner2.png"),
          textRight: false,
        },
        {
          desktopImage: require("@/assets/images/worldappeal/wbanner2.png"),
          textRight: false,
        },
      ],
      loading: false,
      funds: [
        {
          people: "204",
          days: "80",
          title: "Support a Child: $5/Day for Food, Clothes, Shelter, Education",
          image: require("@/assets/images/worldappeal/appeal1.png"),
          progress: 20,
          amount: 4438,
          funded: "50K",
        },
        {
          people: "1587",
          days: "140",
          title: "Support Orphans with Dr. Yasir Qadhi: Build a £500,000 Fund",
          image: require("@/assets/images/worldappeal/appeal2.png"),
          progress: 20,
          amount: 55263,
          funded: "631.4K",
        },
        {
          people: "94",
          days: "55",
          title: "Sri Laka, Iftar Ramadan For The Poor In Remote Villages.",
          image: require("@/assets/images/worldappeal/appeal3.png"),
          progress: 32,
          amount: 2407,
          funded: "15K",
        },
      ],
      blogs: [
        {
          title: "ZAKAT CALCULATOR",
          image: require("@/assets/images/worldappeal/blog1.png"),
          content:
            "Education is a key that unlocks many opportunities. It is a way out of poverty; a driving force for social change; and an important aspect of personal development and welfare. This is why it is one of the main area’s of focus for us here at World Appeal.",
        },
        {
          title: "AQIQAH",
          image: require("@/assets/images/worldappeal/blog2.png"),
          content:
            "We are committed to fighting food poverty and providing support to those that do not have access or have limited access to food. By giving monthly, or donating to our Food Fund you will be supporting World Appeal in our work tackling hunger around the world.",
        },
        {
          title: "FITRANA",
          image: require("@/assets/images/worldappeal/blog3.png"),
          content:
            "Water is a basic necessity for life, unfortunately, in 2021 many people spend their entire day searching for clean drinking water and in some cases are unsuccessful and have to use contaminated water sources. Help us install wells and pumps.",
        },
      ],
      request: false,
      receiver: "aseefjawaid123@gmail.com",
      mailObj: {
        name: "",
        email: "",
        plans: "",
        address: "",
        phone: "",
      },
    };
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast };
  },
  methods: {
    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";
    },
    formSubmit() {
      console.log(this.mailObj);
      // this.request = true;

      // const config = {
      //   method: "post",
      //   url: "https://mail.appick.io/cloudious",
      //   data: {
      //     emailTo: this.receiver,
      //     subject: "World Appeal | Fundraising",
      //     text: `Name: ${this.mailObj.name}\nEmail: ${this.mailObj.email}\nPlans: ${this.mailObj.plans}`,
      //     html: "",
      //   },
      // };
      // axios(config)
      //   .then((res) => {
      //     // console.log(res);
      //     if (res.data.status == "success") {
      //       // close the popup
      //       this.toast.success("Message sent successfully!");
      //       this.mailObj = {
      //         name: "",
      //         email: "",
      //         message: "",
      //       };

      //       this.request = false;
      //     } else {
      //       this.toast.error("Something went wrong!");
      //       this.request = false;
      //     }
      //   })
      //   .catch((err) => {
      //     // console.log(err);
      //     this.toast.error("Something went wrong!");
      //     this.request = false;
      //   });
    },
  },
};
</script>

<style></style>
