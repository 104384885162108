<template>
  <div>
    <Splide
      id="main-slider"
      class="slider"
      :options="{
        drag: false,
        gap: 0,
        perPage: 1,
        perMove: 1,
        type: 'fade',
        arrows: false,
        pagination: false,
        autoplay: true,
        interval: 6000,
        pauseOnHover: false,
        easing: 'linear',
      }"
    >
      <SplideSlide v-for="(item, ind) in slideData" :key="ind">
        <div
          class="slide"
          :style="{
            'background-image': 'url(' + item.desktopImage + ')',
            'background-position': item.textRight ? '10% 0' : '50% 50%',
          }"
        >
          <!-- screenSize ? 'none' : -->
          <div class="slidercontainer w-container">
            <!-- <div class="slidediv" :class="{ setRight: item.textRight }">
              <h1 class="bannerHeading">Complete</h1>
              <h1 class="bannerHeading">
                <span class="bg-colored">Your Zakat</span>
              </h1>
              <h1 class="bannerHeading">
                <span class="bg-colored">2024</span>
              </h1>
              <a
                data-w-id="be5c5993-4f79-d253-2d3c-1a189defd3f2"
                href="#"
                class="button-primary w-button bannerButton"
                @click="toggleDonate('open')"
                >Donate Now</a
              >
            </div> -->
          </div>
        </div>
      </SplideSlide>
    </Splide>

    <section class="storysection wf-section" v-if="false">
      <div class="container-2">
        <div class="row">
          <div class="col-lg-8 quick-donation-bg" :style="{ 'background-image': 'url(' + quickBg + ')' }"></div>
          <div class="col-lg-4 quick-donation">
            <form class="d-flex flex-column">
              <h1 class="quick-heading">Quick Donation</h1>

              <div class="input-group mt-4 quick-input">
                <div class="input-group-prepend">
                  <span class="input-group-text">£</span>
                </div>
                <input
                  ref="amount"
                  type="number"
                  v-model="quickAmount"
                  required
                  class="form-control"
                  @focus="selectedAmount = 'Custom'"
                />
              </div>
              <div class="quick-button-wrapper">
                <div
                  v-for="(item, ind) in quickData"
                  :key="item"
                  class="quick-button"
                  :class="selectedAmount == item && 'selected'"
                  @click="setAmount(item)"
                >
                  <sup v-if="ind != quickData.length - 1">£</sup>{{ item }}
                </div>
              </div>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-success btn-block d-flex align-items-center justify-content-center quick-submit"
                  type="submit"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="storysection wf-section" v-if="false">
      <div class="container-2">
        <h1 class="centered-heading left-bordered"><span class="highlightheading">faqs</span></h1>

        <div class="accordian-wrapper">
          <div class="faq-item" v-for="(item, ind) in faqData" :key="ind">
            <div class="faq-title" @click="openFaq(ind)">
              <div class="faq-text">{{ item.text }}</div>
              <i :class="faqState[ind] ? 'light-icon-chevron-up' : 'light-icon-chevron-down'" class="faq-icon"></i>
            </div>
            <LvCollapsible :show="faqState[ind]">
              <div class="faq-answer">
                <p style="color: inherit">
                  {{ item.answer }}
                </p>
              </div>
            </LvCollapsible>
          </div>
        </div>
      </div>
    </section>

    <section class="storysection wf-section">
      <div class="container-2">
        <h1 class="centered-heading"><span class="highlightheading">Who</span> We are</h1>
        <div class="underlined-div"></div>
        <div class="about-wrapper">
          <div class="about-images-grid">
            <div>
              <img src="@/assets/images/worldappeal/about1.png" alt="" />
            </div>
            <div>
              <img src="@/assets/images/worldappeal/about2.png" alt="" />
            </div>
            <div>
              <img src="@/assets/images/worldappeal/about3.png" alt="" />
            </div>
          </div>

          <div class="">
            <div class="about-content-para">
              Founded in 2011 by a group of altruistic individuals committed to making a difference to the neediest and
              most vulnerable in society, World Appeal initially begun by providing vital support across the developing
              world working to prevent and relieve poverty, improve educational literacy and tackle the urgent problem
              of scarcity of clean drinking water, by caring for orphans, supporting the development of local schools
              and through the installation of water pumps and water wells in addition to providing emergency and
              disaster relief as part of the organisation’s response to global humanitarian crises.
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="storysection gradient-section wf-section">
      <div class="container-2">
        <img src="@/assets/images/world-appeal-white.png" alt="" class="bg-image" />
        <h1 class="centered-heading purple left-bordered"><span class="highlightheading-white">Our</span> Vision</h1>
        <div class="about-content">
          <div class="about-content-para para-white">
            After almost a decade of serving the world’s neediest in some of the most remote destinations across the
            developing world including Africa, South Asia and the Middle East, in 2020, World Appeal began a process of
            major organisational reform. We embarked upon an ambitious capacity development programme streamlining its
            projects and services to continue serving the neediest and most vulnerable in society, by focusing the
            charity’s resources to five primary areas of impact. These include tackling water scarcity, education and
            skills inequality, socio-economic inequality, food inequality, and last but not least, health inequality.
            <br />
            <br />
            Through its vital interventions, World Appeal aims to continue transforming the lives of its beneficiaries
            and empower society’s most vulnerable to create sustainable change, in accordance with the charity’s
            mission, to build sustainable communities through the advancement of equity and equality of opportunity.
            This work will continue across the developing world, particularly, in areas of most acute need. However, to
            reflect the organisation’s increased focus on domestic support across the UK, many of World Appeal’s future
            projects and services will be delivered for the benefit of UK beneficiaries, in order to supplement the
            continued support provided to international beneficiaries.
          </div>
        </div>
      </div>
    </section>

    <section class="storysection wf-section">
      <div class="container-2">
        <!-- <h1 class="centered-heading left-bordered"><span class="highlightheading">Our</span> Impact</h1> -->
        <figure class="video-wrapper">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/KAq8o-6k1iI?si=zwOuFjHvtjfXiCzj"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </figure>
      </div>
    </section>

    <section class="team-circles-2 wf-section">
      <div class="container-6">
        <h1 class="centered-heading"><span class="highlightheading">How</span> We are helping</h1>
        <div class="underlined-div"></div>
        <div v-if="loading" class="custom-loader-wrapper">
          <div class="custom-loader"></div>
        </div>
        <div v-else class="team-grid-2">
          <div
            v-for="data in helpingData"
            :key="data.id"
            id="w-node-_944ed45d-0b36-0fe2-68a3-da8baa79c596-5f1c185e"
            class="team-card-2"
          >
            <!-- :href="'/single-news?id=' + data.id" -->
            <a href="#" class="card-wrapper">
              <img :src="data.image" loading="lazy" alt="help" class="team-member-image-2" />
              <div class="newdetailsdiv">
                <div class="team-member-name-2 newslink">
                  {{ data.title }}
                </div>
                <p class="team-member-position-2">{{ data.content }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="storysection wf-section">
      <div class="container-2">
        <h1 class="centered-heading"><span class="highlightheading">Our Priorites</span> for action</h1>
        <div class="underlined-div"></div>
        <div class="action-wrapper">
          <div class="action-div" v-for="item in actionData">
            <div class="action-item" :key="item.title">
              <img :src="item.image" alt="" />
            </div>
            <h2 class="action-title">{{ item.title }}</h2>
          </div>
        </div>
      </div>
    </section>

    <section class="storysection wf-section">
      <div class="container-2">
        <div class="about-wrapper">
          <div class="about-images-grid">
            <div>
              <img src="@/assets/images/worldappeal/policy.png" alt="" />
            </div>
          </div>

          <div class="">
            <h1 class="centered-heading"><span class="highlightheading">Our Donation</span> policy</h1>
            <div class="underlined-div"></div>
            <div class="about-content-para" style="margin-top: 20px">
              Six pence of every pound donated goes to support our operating costs whilst the remaining four pence goes
              to getting your donations to it’s rightful destinations.
              <br />
              <br />
              £0.90p of every pound donated goes to the appeals you select. <br />
              The administrative costs of the charity are covered by: <br />
              <ul>
                <li>Six pence of every pound you donate</li>
                <li>Donations specified for supports & costs</li>
                <li>Specific fundraisers for World Appeal</li>
              </ul>
              <br />
              The UK Government’s Gift Aid Scheme will reclaim tax from your donation (where specified). Any funds left
              over will be put towards other appeals.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import axios from "axios";
import LvSkeleton from "lightvue/skeleton";
import LvCollapsible from "lightvue/collapsible";

export default {
  components: {
    Splide,
    SplideSlide,
    LvSkeleton,
    LvCollapsible,
  },
  data() {
    return {
      faqData: [
        {
          text: "Can I Donate in Honor of Someone?",
          answer:
            "Absolutely! Donating in honor of a loved one is a meaningful way to celebrate their life, achievements, or commemorate special occasions. Crisis Aid will provide a personalized certificate acknowledging your gift in their honor. This is a beautiful gesture that leaves a lasting impact on the lives of many, reflecting the spirit and values of the person you choose to honor.",
        },
        {
          text: "How Does My Donation Help?",
          answer:
            "Donating a water well/pump through Crisis Aid means you're directly supporting communities where clean water is scarce, by funding the drilling, installation, and maintenance of wells.",
        },
      ],
      faqState: [false, false],
      quickBg: require("@/assets/images/worldappeal/about3.png"),
      quickData: [10, 30, 50, 100, 200, "Custom"],
      quickAmount: 0,
      selectedAmount: 0,
      slideData: [
        {
          desktopImage: require("@/assets/images/worldappeal/wbanner2.png"),
          textRight: false,
        },
        {
          desktopImage: require("@/assets/images/worldappeal/wbanner2.png"),
          textRight: false,
        },
        {
          desktopImage: require("@/assets/images/worldappeal/wbanner2.png"),
          textRight: false,
        },
      ],
      loading: false,
      helpingData: [
        {
          image: require("@/assets/images/worldappeal/helping1.png"),
          title: "School Renovation",
          content:
            "Renovation of a local school in Bandung Indonesia including full infrastructure upgrade to facilitate study",
        },
        {
          image: require("@/assets/images/worldappeal/helping2.png"),
          title: "Water Pumps",
          content:
            "Installed 100+ water pumps in Pakistan in the Sindh province providing clean drinking water to needy people.",
        },
        {
          image: require("@/assets/images/worldappeal/helping3.png"),
          title: "Qurbani",
          content: "Qurbani (religious sacrifice) in Somalia, India, Pakistan, African countries, and the UK.",
        },
        {
          image: require("@/assets/images/worldappeal/helping4.png"),
          title: "Electricity Supply",
          content: "Electric project in Malawi providing vital electricity to a local Maternity Ward.",
        },
        {
          image: require("@/assets/images/worldappeal/helping1.png"),
          title: "School Renovation",
          content:
            "Renovation of a local school in Bandung Indonesia including full infrastructure upgrade to facilitate study",
        },
        {
          image: require("@/assets/images/worldappeal/helping2.png"),
          title: "Water Pumps",
          content:
            "Installed 100+ water pumps in Pakistan in the Sindh province providing clean drinking water to needy people.",
        },
        {
          image: require("@/assets/images/worldappeal/helping3.png"),
          title: "Qurbani",
          content: "Qurbani (religious sacrifice) in Somalia, India, Pakistan, African countries, and the UK.",
        },
        {
          image: require("@/assets/images/worldappeal/helping4.png"),
          title: "Electricity Supply",
          content: "Electric project in Malawi providing vital electricity to a local Maternity Ward.",
        },
      ],
      actionData: [
        { image: require("@/assets/images/worldappeal/education.svg"), title: "Education" },
        { image: require("@/assets/images/worldappeal/water.svg"), title: "Water" },
        { image: require("@/assets/images/worldappeal/food.svg"), title: "Food" },
      ],
    };
  },
  methods: {
    toggleDonate(text) {
      const sec = document.getElementsByClassName("donatepopup");
      sec[0].style.display = text == "open" ? "flex" : "none";
    },

    openFaq(index) {
      this.faqState = this.faqState.map((el, ind) => {
        if (ind == index) el = !el;
        else el = false;
        return el;
      });
    },
    setAmount(val) {
      this.selectedAmount = val;
      if (val == "Custom") {
        this.$refs.amount.focus();
      } else this.quickAmount = val;
    },
  },
};
</script>
