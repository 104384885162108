<template>
  <section class="other-section wf-section">
    <div class="container-2">
      <div v-if="loading" class="custom-loader-wrapper">
        <div class="custom-loader"></div>
      </div>
      <div class="" v-else>
        <h1 class="centered-heading">
          <span class="highlightheading">Terms</span> And Conditions
        </h1>

        <div class="newscontent">
          These terms and conditions apply whenever you visit or use this
          website.
          <br /><br />
          Before using the World Appeal website or donating to any of the
          appeals we promote, please read these terms and conditions carefully.
          If you are under the age of 18, make sure your parent or guardian
          reads and discusses these terms with you. These terms apply to all of
          our websites’ content as well as any email correspondence you may have
          with us. These terms are supplemented by our privacy statement, which
          should be read in addition to these terms.
          <br /><br />

          <h4>General Provisions</h4>
          These terms apply when you visit or use one of our websites, as well
          as when you donate to an appeal, project or campaign through one of
          our sites.
          <br /><br />

          <h4>Changes</h4>
          We reserve the right to change these terms at any time, and the new
          terms will be posted on our websites. Except for any donation we have
          already accepted from you before the new terms are posted, which will
          continue to be subject to the terms in effect when your order was
          accepted, they will apply from the date we post them. Please review
          these terms each time you visit one of our websites. You will be bound
          by the most recent terms if you continue to use our sites.
          <br /><br />

          <h4>Using our sites</h4>
          You agree to use our sites only in a way that complies with all
          applicable laws and regulations, is consistent with these terms, and
          does not infringe on the rights of others or restrict or inhibit their
          use and enjoyment of our sites (including, without limitation, by
          hacking). We reserve the right to refuse any user access to any of our
          websites at any time, for any reason.
          <br /><br />

          <h4>Privacy statement</h4>
          <ul>
            <li>
              In accordance with our privacy statement, World Appeal collects,
              stores, and processes information about you. You consent to such
              use, storage and processing by using our websites.
            </li>
            <li>
              If you have a complaint about any of our websites' content, please
              send an email to
              <a class="" href="mailto:info@worldappeal.org.uk"
                >info@worldappeal.org.uk</a
              >
              with the following information:
            </li>
            <li>
              Your name, company or organisation name if applicable and contact
              information (both a land address, telephone number and email).
            </li>
            <li>
              Details of the exact content that you're complaining about, as
              well as why you're complaining about it.
            </li>
            <li>
              You must confirm that the information you've provided is correct,
              complete and not misleading.
            </li>
            <li>
              Please keep in mind that without this information, no action can
              be taken.
            </li>
          </ul>

          <h4>
            You may not post or transmit any of the following materials to this
            website:
          </h4>
          Our services related to prescriptions and medical advice are provided
          by qualified healthcare professionals. However, it's important to note
          that the information provided through these services is for
          informational purposes only and should not replace professional
          medical advice.
          <br /><br />
          <ul>
            <li>
              That includes anything that is defamatory, illegal, abusive,
              vulgar, hateful, harassing, liable to incite racial hatred,
              blasphemous, discriminatory, pornographic, sexually-oriented,
              threatening, or invading of a person's privacy.
            </li>
            <li>
              That which you do not own or for which you do not have all of the
              necessary licences and/or approvals.
            </li>
            <li>
              What is technically injurious (including, without limitation,
              computer viruses, logic bombs, Trojan horses, worms, harmful
              components, corrupted data or other malicious software or harmful
              data).
            </li>
          </ul>
          Falsely stating or otherwise misrepresenting your affiliation with
          anyone or any entity.
          <br /><br />
          If you post or transmit any material of the type prohibited above to
          any of our websites and we incur any costs, expenses, liability, or
          losses as a result, you agree to reimburse us for all such costs,
          expenses, liability, or losses.
          <br /><br />
          If you upload or send any content to one of our websites, you grant us
          permission to use, distribute, modify and publish that content on our
          other websites and publications. You also grant us the right to use
          your name in connection with such content, and we shall be entitled to
          reveal your identity (or any other information we have about you) to
          any third party alleging that any of that material infringes on their
          rights, or to any government or regulatory authority that has the
          power to require us to do so.
          <br /><br />
          Any material or posting you make on any of our websites may be removed
          or edited at any time.
          <br /><br />

          <h4>Intellectual Property Rights</h4>
          We own all copyright, trademarks, and other intellectual property
          rights in our websites, as well as all photos, footage, designs,
          pictures, text, software, data, and other material on or produced by
          our sites. Only as expressly authorised by us are you allowed to use
          the sites and this material.
          <br /><br />
          You may print and download extracts from our websites for personal,
          non-commercial use, as well as for private research or teaching
          purposes, provided that:
          <br /><br />
          <ul>
            <li>
              Copyright and source attributions are printed and copied, as well.
            </li>
            <li>
              The materials are not altered in any way and are not included in
              any other publications.
            </li>
            <li>
              Any document is completely printed and copied and it is never used
              in a derogatory or misleading manner.
            </li>
            <li>
              The material is not used in a way that could harm our reputation
              or impair our ability to achieve our charitable goals.
            </li>
            <li>
              Without first receiving our written consent, you may not make any
              other use of the material on our websites. You must not, in
              particular, do the following without first obtaining.
            </li>
          </ul>

          <h4>Our written permission:</h4>
          <ul>
            <li>
              Any material from our sites may be used in any other work or
              publication, whether in hard copy or electronic form.
            </li>
            <li>
              Use or publish any material on our sites for commercial purposes
              (other than as necessary for the purpose of viewing the sites in
              the course of business).
            </li>
            <li>
              Please email your request to
              <a class="" href="mailto:info@worldappeal.org.uk"
                >info@worldappeal.org.uk</a
              >
              if you wish to use any material from any of our sites in any way
              other than as stated above.
            </li>
            <li>
              Any photographic, text, or other graphic images contained in any
              products or publications provided to you are copyright works that
              may not be copied, reproduced, licenced or exploited in any way.
            </li>
            <li>
              The name and logo of World Appeal are protected by the Patent Act.
              Other logos and names on our sites may be of World Appeal or their
              respective owners' trademarks. We do not grant permission for the
              use of any of these trademarks, names, or logos, and such use may
              be considered a violation of the holder's rights.
            </li>
            <li>
              Any rights in these terms that are not expressly granted are
              reserved.
            </li>
          </ul>

          <h4>Disclaimers</h4>
          While we make every effort to ensure that the information on our
          websites is correct, we cannot guarantee its accuracy or completeness.
          The information on our websites may be out of date, and we make no
          guarantees that it will be updated. We reserve the right to make
          changes to the information on our websites, as well as the appeals and
          prices mentioned therein, at any time and without notice.
          <br /><br />
          You may not access our sites if their contents are illegal or unlawful
          in your country. If you access our sites from outside the United
          Kingdom, you do so at your own risk and are responsible for adhering
          to local laws.
          <br /><br />
          We go to great lengths to ensure that all donations are made through a
          secure link. However, the security of information and payments
          transmitted over the internet cannot be assured, and since we are not
          responsible for the secure link's operation, we will not be liable for
          any loss you may suffer if a third party gains unauthorised access to
          any information you provide.
          <br /><br />
          We accept donations via our website and social media using Stripe,
          Enthuse Givebrite and Facebook. We consider these to be reliable
          sources and well-known platforms that are used by a variety of
          charities. However, we disclaim any responsibility for their mistakes
          or errors, as well as any losses you may incur as a result of using
          their platform. Your financial transaction becomes their
          responsibility if you reach their platform for payment. If you'd like
          to contact them, please contact our office for their contact
          information. We make no guarantee that the functions contained in our
          sites will be uninterrupted or error-free, that defects will be fixed,
          or that our sites or the servers that make them available will be free
          of viruses or bugs, or that the materials will be fully functional,
          accurate, or reliable. Except in the case of death or personal injury,
          we will not be liable for any loss, disruption, or damage to your data
          or computer system, or any other damages (including, without
          limitation, loss of profit or loss of use) arising out of your use or
          delay or inability to use our sites, their content, or any link to
          another website, whether arising in contract, tort (including
          negligence), or otherwise.
          <br /><br />
          Any person who wears clothing with the World Appeal logo is not
          permitted to collect any donation or monitory donation on behalf of
          World Appeal. World Appeal is not liable for any actions taken by
          anyone wearing the World Appeal logo on their clothing. An ID card
          will be issued to anyone who works for World Appeal in any capacity or
          collects donations or money on behalf of World Appeal. Before handing
          over any gifts, please ask for identification. You can also check by
          calling the numbers listed on the ID cards or visit our website for
          our contact information.
          <br /><br />

          <h4>Complaints Procedure</h4>
          All of World Appeal's donors, volunteers, helpers, and supporters are
          treated with the utmost respect. We believe that we are merely a
          stepping stone to ensure that your donations reach the world's poor
          and needy. You are the driving force behind this charity endeavour. As
          a result, we serve the public and are conscious of our
          responsibilities. We will, nonetheless, make mistakes, despite our
          best efforts, because that is human nature. We need your assistance,
          advice, and assistance in correcting them so that we can provide a
          better service to everyone.
          <br /><br />
          To register your concerns or complaints, please email us at
          info@worldappeal.org.uk.
          <br /><br />
          If you file a complaint within one month of the incident, we will make
          every effort to respond and provide you with a response within 14
          days. If the situation necessitates additional time, we will notify
          you in writing. Within 30 days, we aim to resolve all grievances and
          conflicts.
          <br /><br />

          <h4>Liability</h4>
          We do not limit our liability in any way to the extent permitted by
          law for fraud, death or personal injury caused by our negligence or
          breach of statutory duty, or any other liability that cannot be
          excluded or restricted under applicable law.
          <br /><br />
          We will not be liable for any loss or damage caused by us, our
          employees, or our agents in the following situations:
          <br /><br />
          <ul>
            <li>
              When you first started using our site or when we formed a contract
              to supply you with goods, loss or damage was not fairly
              foreseeable to both you and us.
            </li>
            <li>
              No breach by us, our employees, or our agents resulted in any loss
              or damage.
            </li>
            <li>
              The loss or damage affects both businesses and non-consumers
              (including loss of profits, revenue, contracts, anticipated
              savings, data, goodwill or wasted expenditure).
            </li>
          </ul>

          <h4>Links</h4>
          Links to third-party websites may be found on our sites. These links
          are provided solely for your convenience. Such websites are not
          necessarily under our control, and we are not responsible for their
          content. The mere presence of these links does not imply any
          endorsement of the content on those websites or any affiliation with
          their owners. You do so entirely at your own risk if you visit any of
          the third-party websites related to or from our websites. We cannot
          ensure that these links will function at all times and we have no
          control over the linked pages' availability.
          <br /><br />
          If you want to link from your website to any of our pages, you can do
          so only if you link to the page rather than replicating it and only if
          you follow the following regulations:
          <br /><br />
          <ul>
            <li>
              The website address must be the only thing in the linking text.
            </li>
            <li>
              You may not alter the size or appearance of any logos on our sites
              by removing, distorting or otherwise.
            </li>
            <li>
              You do not present any false information about us or misrepresent
              your relationship with us.
            </li>
            <li>
              Without our express written consent, you may not use any World
              Appeal logo displayed on our sites in any other way.
            </li>
            <li>You must not link to a website that is not your own.</li>
            <li>
              Your website does not contain any content that is offensive,
              pornographic, infringes on the intellectual property rights of
              others, or otherwise violates all applicable rules and
              regulations.
            </li>
          </ul>
          If the delay or breach is due to acts of God, civil commotion, riots,
          malicious damage, floods, drought, fire, legislation, failure of an
          isp or telecommunications provider or any other cause outside our
          reasonable control, we will not be liable to you for any delay or
          breach of our commitments. This has no bearing on your legal rights.
          <br /><br />

          <h4>Events beyond our control</h4>
          Links to third-party websites may be found on our sites. These links
          are provided solely for your convenience. Such websites are not
          necessarily under our control, and we are not responsible for their
          content. The mere presence of these links does not imply any
          endorsement of the content on those websites or any affiliation with
          their owners. You do so entirely at your own risk if you visit any of
          the third-party websites related to or from our websites. We cannot
          ensure that these links will function at all times and we have no
          control over the linked pages' availability.
          <br /><br />
          If you want to link from your website to any of our pages, you can do
          so only if you link to the page rather than replicating it and only if
          you follow the following regulations:
          <br /><br />
          <ul>
            <li>
              The website address must be the only thing in the linking text.
            </li>
            <li>
              You may not alter the size or appearance of any logos on our sites
              by removing, distorting or otherwise.
            </li>
            <li>
              You do not present any false information about us or misrepresent
              your relationship with us.
            </li>
            <li>
              Without our express written consent, you may not use any World
              Appeal logo displayed on our sites in any other way.
            </li>
            <li>You must not link to a website that is not your own.</li>
            <li>
              Your website does not contain any content that is offensive,
              pornographic, infringes on the intellectual property rights of
              others, or otherwise violates all applicable rules and
              regulations.
            </li>
          </ul>

          <h4>Law, courts and language</h4>
          These terms, as well as your use of our websites and our provision of
          services, are governed by and interpreted in accordance with English
          law. In the event of a dispute arising out of or relating to these
          terms, your use of our sites, or our provision of any services, the
          English courts will have jurisdiction. These terms, as well as our
          websites, are only available in English.
          <br /><br />

          <h4>Donations</h4>
          All appeal donations are subject to availability, and we reserve the
          right to refuse service to any company or individual.<br />
          Your donation represents an offer to purchase the appeal that you have
          specified, but it is not binding on us until we have approved it.
          <br /><br />
          All donations made in response to a specific appeal will be closed and
          used only for that purpose. The following is an exception:
          <br /><br />
          <ul>
            <li>
              If we are unable to use your donations in the appeal you
              requested, we will notify you. Because the appeal is currently
              unavailable, your donation will be used to fund a suitable
              replacement.
            </li>
            <li>
              If we raise more money than we expected for a campaign, appeal, or
              project. Any additional funds will be used to support similar
              projects and appeals.
            </li>
            <li>
              If we do not raise enough funds for an appeal to which you have
              donated, management will use their discretion to either add funds
              from general donations to complete the appeal or move donations
              from the inadequate fund to another similar fund.
            </li>
            <li>
              If an appeal appears on our sites but is not available, is
              incorrectly priced, or otherwise misstated, or we are unable to
              distribute it, we will apply your donation to a similar appeal.
            </li>
            <li>
              We will accept your donation by email shortly after we receive it,
              detailing the appeals to which you have donated. This email is
              sent out automatically.
            </li>
            <li>
              When we receive the full amount of the appeal into our bank
              account, we will accept your donation for an appeal and complete
              the contract between you and us.
            </li>
            <li>
              Visa Debit, Switch, Mastercard and Visa cards are all accepted.
              For online donations, we are unable to accept payment by cheque.
            </li>
            <li>
              We also gather and properly apply donations made through Islamic
              Quick Links, such as Fidya, Kaffarah, Zakat and Sadaqah which are
              limited by Shariah Criteria, with the management selecting the
              country based on overall considerations.
            </li>
            <li>
              Please contact our team by email, phone, or postal if you wish to
              cancel your donation before it is used for your requested appeal
              or project. For more information, please get in touch with us.
            </li>
            <li>
              We will honour your cancellation if an order has not been placed
              to distribute the aid.
            </li>
            <li>
              We cannot refund or cancel your donations if they have already
              been used.
            </li>
          </ul>

          <h4>Double Donation</h4>
          Please <b>WAIT</b> and <b>DO NOT REFRESH THE PAGE</b> if your browser
          freezes while making a donation. You will be charged <b>TWICE</b> if
          you <b>REFRESH</b> the page. If this happens, please contact us.
          <br /><br />

          <h4>Refund Policy</h4>
          Please contact our team by email, phone, or postal if you want to
          cancel your donation before it is used for your requested appeal or
          project:
          <br /><br />
          <ul>
            <li>World Appeal, 126-128 Burypark Road, Luton, LU1 1HE</li>
            <li>0300 365 7869 | 0786 400 1000</li>
            <li>
              <a class="" href="mailto:info@worldappeal.org.uk"
                >info@worldappeal.org.uk</a
              >
            </li>
          </ul>
          If an order to distribute the aid has not been placed, we will honour
          your cancellation and refund your entire payment. We do not provide
          refunds or cancellations if your donations have been used or if the
          order for the donation to be processed has been granted.
          <br /><br />
          We will try to provide a full refund within 3 working days if your
          donation was made by accident, mistake, or computer error, as long as
          you notify us within 14 days. We cannot ensure a refund for any
          accident, mistake, or computer error reported to us after 14 days if
          we are unable to trace the error on our systems.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Terms",
  data() {
    return {
      loading: false,
    };
  },
};
</script>
<style></style>
